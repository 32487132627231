import React from "react";
import {
  evaluate,
  complexFormExpressionEvaluationError,
  nullThrows,
  prefixError,
  reduce,
  CommitConfig,
  Expression,
  ObjectValue,
  Query,
  Value,
  asError,
  Schema,
  Logs,
} from "@hypertune/sdk/src/shared";
import {
  getSchemaAndQuery,
  replaceVariablePlaceholders,
  getEmptyPermissions,
  queryObjectTypeName,
  getGraphqlSchema,
  getQueryCodeFromQuery,
  ProjectTokenMapWithMeta,
} from "@hypertune/shared-internal";
import getDefaultQuery from "@hypertune/shared-internal/src/schema/getDefaultQuery";
import { objectValueSchema } from "@hypertune/shared-internal/src/generated/schemas";
import { CodesandboxLogo } from "@phosphor-icons/react";
import {
  borderRadiusPx,
  darkGreyHex,
  greyHex,
  intentPrimaryHex,
  smallFontSize,
} from "../../lib/constants";
import { DebugEditorState, ImplementationContext } from "../../lib/types";
import CodeEditor from "../../components/CodeEditor";
import ErrorMessage from "../../components/ErrorMessage";
import ExpressionControl from "./logic/expression/ExpressionControl";
import Selector from "../../components/Selector";
import TextInput from "../../components/input/TextInput";
import Button from "../../components/buttons/Button";
import formatSnippet from "../../lib/formatSnippet";
import useShowSetupModal from "./setup/useShowSetupModal";
import { getQueryToken } from "../../lib/tokens";
import { useHypertune } from "../../generated/hypertune.react";

const resultTabs = ["Expression", "JSON", "Code snippets"] as const;
type ResultTab = (typeof resultTabs)[number];

function DebugEditor({
  isVisible,
  meId,
  projectTokens,
  schema,
  schemaCode,
  implementationContext,
  expression,
  commitConfig,
  debugEditorState,
  setDebugEditorState,
}: {
  isVisible: boolean;
  meId: string;
  projectTokens: ProjectTokenMapWithMeta;
  schema: Schema;
  schemaCode: string;
  implementationContext: ImplementationContext;
  expression: Expression | null;
  commitConfig: CommitConfig;
  debugEditorState: DebugEditorState;
  setDebugEditorState: (newDebugEditorState: DebugEditorState) => void;
}): React.ReactElement {
  const content = useHypertune().content();
  const { snippets } = content.preview().get();
  const snippetTabs = ["SDK", ...snippets.map((snippet) => snippet.name)];
  const snippetNameToSnippet = Object.fromEntries(
    snippets.map((snippet) => [snippet.name, snippet])
  );

  const [, setShowSetupModal] = useShowSetupModal();
  const [selectedResultTab, setSelectedResultTab] =
    React.useState<ResultTab>("Expression");
  const [selectedSnippetTab, setSelectedSnippetTab] = React.useState("SDK");

  let errorMessage: string | null = null;
  let variableValues: ObjectValue = {};
  let query: Query<ObjectValue> | null = null;
  let reducedExpression: Expression | null = null;
  let evaluateResult: { value: Value; logs: Logs } | null = null;

  const projectToken = getQueryToken(projectTokens);

  try {
    if (!isVisible) {
      throw new Error("Not visible");
    }

    const graphqlSchema = getGraphqlSchema(schemaCode);

    variableValues = prefixError(
      () => objectValueSchema.parse(JSON.parse(debugEditorState.variablesCode)),
      "Variables Error: "
    );

    const data = getSchemaAndQuery({
      graphqlSchema,
      queryCode: debugEditorState.queryCode,
      silentlySkipInvalidFieldNames: false,
      markQueryFieldArgumentsPartial:
        debugEditorState.markQueryFieldArgumentsPartial,
      useSharedFragments: true,
    });
    query = data.query;

    const replacedVariableValues = replaceVariablePlaceholders(
      variableValues,
      debugEditorState.userAgent,
      debugEditorState.referer
    );

    reducedExpression = prefixError(
      () =>
        reduce(
          implementationContext.splits,
          commitConfig,
          query,
          replacedVariableValues,
          nullThrows(expression, "Expression is null"),
          /* allowMissingVariables */ false
        ),
      "Reduction Error: "
    );

    try {
      evaluateResult = evaluate(reducedExpression);
    } catch (error) {
      const { message } = asError(error);
      if (message !== complexFormExpressionEvaluationError) {
        throw new Error(`Evaluation Error: ${message}`);
      }
    }
  } catch (error) {
    const { message } = asError(error);
    errorMessage = message;
  }

  console.debug("Debugger Error:", errorMessage);
  console.debug("Query", query);
  console.debug("Reduced Expression:", reducedExpression);
  console.debug("Evaluate Result:", evaluateResult);
  console.debug("Test:", {
    query,
    expected: { reducedExpression, evaluateResult },
  });

  const leftWidthPercentage = 35;

  return (
    <div
      style={{
        display: isVisible ? "flex" : "none",
        flexDirection: "row",
        flexGrow: 1,
        // https://stackoverflow.com/questions/33605552/how-to-prevent-a-flex-item-height-to-overflow-due-to-its-content
        minHeight: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: `${leftWidthPercentage}%`,
        }}
      >
        <div className="flex min-h-[43px] flex-row items-center justify-between border-b px-3 py-2 text-sm">
          QUERY
          <div className="flex flex-row gap-2">
            <Button
              weight="outlined"
              text="Reset"
              size="small"
              disabled={!debugEditorState.hasChanges}
              onClick={() => {
                const newQuery = getDefaultQuery({
                  schema,
                  objectTypeNames: [queryObjectTypeName],
                  includeArguments: true,
                  includeDeprecated: true,
                  useSharedFragments: false,
                  markQueryFieldArgumentsPartial: false,
                });
                setDebugEditorState({
                  ...debugEditorState,
                  hasChanges: false,
                  queryCode: newQuery
                    ? getQueryCodeFromQuery({
                        query: newQuery,
                        includeComments: true,
                        includeArguments: false,
                        inlineSharedFragments: false,
                      }) || ""
                    : "",
                });
              }}
            />
            <Button
              weight="outlined"
              text="Format"
              size="small"
              disabled={
                !query ||
                debugEditorState.queryCode ===
                  getQueryCodeFromQuery({
                    query,
                    includeComments: false,
                    includeArguments: true,
                    inlineSharedFragments: false,
                  })
              }
              onClick={() => {
                if (query) {
                  setDebugEditorState({
                    ...debugEditorState,
                    hasChanges: true,
                    queryCode:
                      getQueryCodeFromQuery({
                        query,
                        includeComments: false,
                        includeArguments: true,
                        inlineSharedFragments: false,
                      }) || "",
                  });
                }
              }}
            />
          </div>
        </div>
        <div
          style={{
            flexGrow: 1,
            overflowY: "auto",
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          <CodeEditor
            code={debugEditorState.queryCode}
            setCode={(newCode: string) => {
              setDebugEditorState({
                ...debugEditorState,
                hasChanges: true,
                queryCode: newCode,
              });
            }}
            language="graphql"
          />
        </div>
        <div
          style={{
            padding: "8px 12px",
            color: darkGreyHex,
            fontSize: smallFontSize,
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          QUERY VARIABLES
        </div>
        <div
          style={{
            maxHeight: "20vh",
            minHeight: "30px",
            overflowY: "auto",
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          <CodeEditor
            code={debugEditorState.variablesCode}
            setCode={(newCode: string) => {
              setDebugEditorState({
                ...debugEditorState,
                variablesCode: newCode,
              });
            }}
            language="json"
          />
        </div>
        {/* https://linear.app/hypertune/issue/HYP-244/ */}
        {/* <div
          style={{
            padding: "8px 12px",
            color: darkGreyHex,
            fontSize: smallFontSize,
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          QUERY SETTINGS
        </div>
        <div
          style={{
            padding: "8px 12px",
            display: "flex",
            flexDirection: "row",
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          <Toggle
            style={{ marginTop: 3 }}
            value={debugEditorState.markQueryFieldArgumentsPartial}
            setValue={(newValue) => {
              setDebugEditorState({
                ...debugEditorState,
                markQueryFieldArgumentsPartial: newValue,
              });
            }}
          />
          <div style={{ marginLeft: 12, marginTop: 4 }}>
            Allow partial field arguments
          </div>
        </div> */}
        <div
          style={{
            padding: "8px 12px",
            color: darkGreyHex,
            fontSize: smallFontSize,
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          USER AGENT
        </div>
        <div
          style={{
            padding: "8px 12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          <TextInput
            value={debugEditorState.userAgent}
            onChange={(newValue) => {
              setDebugEditorState({ ...debugEditorState, userAgent: newValue });
            }}
            trimOnBlur
            placeholder="Enter user agent here"
            readOnly={false}
          />
        </div>
        <div
          style={{
            padding: "8px 12px",
            color: darkGreyHex,
            fontSize: smallFontSize,
            borderBottom: `1px solid ${greyHex}`,
          }}
        >
          REFERER URL
        </div>
        <div
          style={{
            padding: "8px 12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <TextInput
            value={debugEditorState.referer}
            onChange={(newValue) => {
              setDebugEditorState({ ...debugEditorState, referer: newValue });
            }}
            trimOnBlur
            placeholder="Enter referer URL here"
            readOnly={false}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          width: `${100 - leftWidthPercentage}%`,
          borderLeft: `1px solid ${greyHex}`,
        }}
      >
        <div className="flex min-h-[43px] flex-row items-center justify-between border-b px-3 py-2 text-sm">
          RESULT
        </div>
        {errorMessage ? (
          <div>
            <ErrorMessage
              style={{ flexGrow: 1, padding: "8px 12px", overflowY: "scroll" }}
              errorMessage={errorMessage}
            />
          </div>
        ) : null}
        {reducedExpression ? (
          <>
            <div
              style={{
                margin: "8px 12px 0 12px",
                alignSelf: "flex-start",
              }}
            >
              <Selector<ResultTab>
                options={resultTabs}
                values={resultTabs}
                selectedValue={selectedResultTab}
                onSelectValue={(resultTab) => setSelectedResultTab(resultTab)}
              />
            </div>
            {selectedResultTab === "Expression" ? (
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  overflow: "auto",
                  margin: "8px 12px",
                }}
              >
                <ExpressionControl
                  context={{
                    meId,
                    fullFieldPath: "",
                    commitContext: {
                      schema,
                      ...implementationContext,
                    },
                    evaluations: {},
                    expressionEditorState:
                      debugEditorState.expressionEditorState,
                    setExpressionEditorState: (newExpressionEditorState) => {
                      setDebugEditorState({
                        ...debugEditorState,
                        expressionEditorState: newExpressionEditorState,
                      });
                    },
                    ignoreErrors: true,
                    readOnly: true,
                    resolvedPermissions: getEmptyPermissions(),
                    expandByDefault: false,
                  }}
                  variables={{}}
                  setVariableName={{}}
                  valueTypeConstraint={{ type: "AnyObjectValueTypeConstraint" }}
                  expression={reducedExpression}
                  setExpression={() => {
                    // Dummy
                  }}
                  lift={() => {
                    // Dummy
                  }}
                  parentExpression={null}
                  includeExpressionOption={() => true}
                />
              </div>
            ) : selectedResultTab === "JSON" ? (
              <div
                style={{
                  flexGrow: 1,
                  overflowY: "auto",
                }}
              >
                {evaluateResult ? (
                  <CodeEditor
                    code={JSON.stringify(evaluateResult.value, null, 2)}
                    setCode={() => {
                      // Dummy
                    }}
                    language="json"
                    readOnly
                  />
                ) : (
                  <div style={{ padding: "12px 16px" }}>
                    Expression is not fully reduced so cannot be evaluated to
                    JSON.
                  </div>
                )}
              </div>
            ) : (
              <>
                <div style={{ margin: "8px 12px", alignSelf: "flex-start" }}>
                  <Selector<string>
                    options={snippetTabs}
                    values={snippetTabs}
                    selectedValue={selectedSnippetTab}
                    onSelectValue={(snippetTab) =>
                      setSelectedSnippetTab(snippetTab)
                    }
                  />
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    overflowY: "auto",
                    margin: "0 12px 0 12px",
                    paddingBottom: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  {selectedSnippetTab === "SDK" ? (
                    <div>
                      <Button
                        text="Open setup wizard"
                        intent="primary"
                        weight="minimal"
                        icon={<CodesandboxLogo color={intentPrimaryHex} />}
                        onClick={() => {
                          setShowSetupModal(true);
                        }}
                      />
                    </div>
                  ) : evaluateResult ? (
                    projectToken ? (
                      <div
                        style={{
                          border: `1px solid ${greyHex}`,
                          borderRadius: borderRadiusPx,
                        }}
                      >
                        <CodeEditor
                          code={formatSnippet(
                            snippetNameToSnippet[selectedSnippetTab].code,
                            {
                              projectToken,
                              variableValues,
                              queryCode: debugEditorState.queryCode,
                            }
                          )}
                          setCode={() => {
                            // Dummy
                          }}
                          language={
                            snippetNameToSnippet[selectedSnippetTab].language
                          }
                          readOnly
                          showButtons="on-hover"
                          style={{ maxWidth: "650px" }}
                        />
                      </div>
                    ) : (
                      <div>Create a token with query access scope first.</div>
                    )
                  ) : (
                    <div>Expression is not fully reduced.</div>
                  )}
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default DebugEditor;
