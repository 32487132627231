import { Expression, VariableExpression } from "@hypertune/sdk/src/shared";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { normal, singlePanelInnerHeight } from "../../../../lib/constants";
import { ExpressionControlContext } from "../../../../lib/types";
import VariableNameControl from "./VariableNameControl";
import ExpressionControl from "./ExpressionControl";
import GoToArrowButton from "../../../../components/buttons/GoToArrowButton";

export default function VariableExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  parentExpression,
  optionsButton,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: VariableExpression;
  parentExpression: Expression | null;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  const variable = variables[expression.variableId];
  const groupIndex = context.variableGroupIndex ?? 0;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: singlePanelInnerHeight,
      }}
    >
      {variable ? (
        <>
          <VariableNameControl
            groupIndex={groupIndex}
            style={{ margin: "2.75px 0 2.75px 0" }}
            schema={context.commitContext.schema}
            variable={variable}
            variableValue={
              variable.value && (
                <ExpressionControl
                  context={{
                    ...context,
                    readOnly: true,
                    hideOptions: true,
                    variableGroupIndex: groupIndex + 1,
                    setSelectedFieldPath: undefined,
                  }}
                  variables={variables}
                  setVariableName={{}}
                  valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
                  expression={variable.value}
                  setExpression={() => {
                    // noop
                  }}
                  lift={() => {
                    // noop
                  }}
                  parentExpression={null}
                  includeExpressionOption={() => false}
                />
              )
            }
            setVariableName={setVariableName[variable.id]}
            // Always readonly, as being able to edit a variable reference
            // does not mean we can actually edit the variable definition
            readOnly
          />
          {context.setSelectedFieldPath && variable.path ? (
            <GoToArrowButton
              onClick={() => context.setSelectedFieldPath?.(variable.path!)}
              isVariable
            />
          ) : optionsButton &&
            !(
              parentExpression && parentExpression.type === "GetFieldExpression"
            ) ? (
            <>
              <div style={{ flexGrow: 1, minWidth: normal }} />
              {optionsButton}
            </>
          ) : null}
        </>
      ) : (
        "Invalid Variable"
      )}
    </div>
  );
}
