import {
  Expression,
  RegexExpression,
  StringExpression,
  getStringExpression,
} from "@hypertune/sdk/src/shared";

import { useCallback, useState } from "react";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import {
  Check,
  MagicWand,
  Sparkle,
  SortAscending,
  SortDescending,
  X,
  ArrowUUpLeft,
} from "@phosphor-icons/react";
import { twJoin } from "tailwind-merge";
import toWords from "@hypertune/sdk/src/shared/helpers/toWords";
import { ExpressionControlContext } from "../../../../lib/types";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import TextArea from "../../../../components/input/TextArea";
import Menu, { MenuItem } from "../../../../components/Menu";
import {
  RephraseAction,
  useRephraseTextMutation,
} from "../../../../generated/graphql";
import Spinner from "../../../../components/icons/Spinner";
import Button from "../../../../components/buttons/Button";
import { useHypertune } from "../../../../generated/hypertune.react";
import { Intent } from "../../../../components/intent";

export type StringExpressionControlProps = {
  context: ExpressionControlContext;
  expression: StringExpression | RegexExpression;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression?: Expression | null;
  setParentExpression?: (newExpression: Expression | null) => void;
  shouldStack: boolean;
  optionsButton: React.ReactNode;
  disablePanelOnSelect?: boolean;
  warningText: string | null;
  dragHandle?: React.ReactNode;
};

export default function StringExpressionControl(
  props: StringExpressionControlProps
): React.ReactElement {
  const { context, expression } = props;
  const readOnly = isReadOnly(context);

  const hypertune = useHypertune();
  const completionsEnabled =
    !readOnly &&
    expression.type === "StringExpression" &&
    !context.disableAICompletions &&
    hypertune.features().aiCompletions({ fallback: false });

  if (completionsEnabled) {
    return (
      <StringExpressionControlInnerWithCompletions
        {...props}
        readOnly={readOnly}
      />
    );
  }

  return <StringExpressionControlInner readOnly={readOnly} {...props} />;
}

function StringExpressionControlInnerWithCompletions({
  readOnly,
  context,
  expression,
  setExpression,
  optionsButton,
  ...props
}: StringExpressionControlProps & { readOnly: boolean }): React.ReactElement {
  const hypertune = useHypertune();
  const [suggestion, setSuggestion] = useState<{
    id: string;
    action: RephraseAction;
    text: string;
  } | null>(null);
  const [rephraseMutation, { loading: rephraseMutationLoading }] =
    useRephraseTextMutation();
  const rephrase = useCallback(
    async (action: RephraseAction) => {
      const resp = await rephraseMutation({
        variables: {
          input: {
            action,
            text: expression.value,
          },
        },
      });
      if (
        resp.data?.rephraseText &&
        resp.data.rephraseText.text !== expression.value
      ) {
        setSuggestion({
          action,
          id: resp.data.rephraseText.id!,
          text: resp.data.rephraseText.text,
        });
      }
    },
    [setSuggestion, rephraseMutation, expression.value]
  );

  return (
    <>
      <StringExpressionControlInner
        {...props}
        readOnly={readOnly}
        context={context}
        expression={expression}
        setExpression={setExpression}
        optionsButton={
          <div className="flex flex-row items-center gap-[2px]">
            <AIMenu rephrase={rephrase} loading={rephraseMutationLoading} />
            {optionsButton}
          </div>
        }
        className="pr-[60px]"
      />
      {suggestion !== null && (
        <TextArea
          readOnly={false}
          placeholder=""
          optionsButton={
            <div className="flex flex-col items-stretch gap-1 pt-2">
              <Button
                text="Accept"
                size="small"
                weight="filled"
                intent="primary"
                icon={<Check size={14} color="white" weight="regular" />}
                onClick={() => {
                  hypertune.events().aiCompletionUsage({
                    args: { id: suggestion.id, status: "accepted" },
                  });
                  setExpression({ ...expression, value: suggestion.text });
                  setSuggestion(null);
                }}
              />
              <Button
                text="Discard"
                size="small"
                weight="outlined"
                intent="neutral"
                icon={<X size={14} weight="regular" />}
                onClick={() => {
                  hypertune.events().aiCompletionUsage({
                    args: { id: suggestion.id, status: "discarded" },
                  });
                  setSuggestion(null);
                }}
              />
              <Button
                text="Retry"
                size="small"
                weight="outlined"
                intent="neutral"
                icon={<ArrowUUpLeft size={14} weight="regular" />}
                onClick={() => {
                  hypertune.events().aiCompletionUsage({
                    args: { id: suggestion.id, status: "retried" },
                  });
                  setSuggestion(null);
                  rephrase(suggestion.action).catch((error) =>
                    console.error("failed to retry ai completion", { error })
                  );
                }}
                className="justify-start"
              />
            </div>
          }
          value={suggestion.text}
          setValue={(newText) =>
            setSuggestion({ ...suggestion, text: newText })
          }
          className="mt-2 min-h-[98px] pr-24"
        />
      )}
    </>
  );
}

function StringExpressionControlInner({
  readOnly,
  context,
  expression,
  setExpression,
  parentExpression,
  setParentExpression,
  shouldStack,
  optionsButton,
  disablePanelOnSelect,
  className,
  warningText,
  dragHandle,
}: StringExpressionControlProps & {
  readOnly: boolean;
  className?: string;
}): React.ReactElement {
  const { expressionEditorState } = context;

  const isExpressionSelected =
    expressionEditorState.selectedItem &&
    expressionEditorState.selectedItem.type === "expression" &&
    expressionEditorState.selectedItem.id === expression.id;

  const placeholder =
    expression.type === "RegexExpression"
      ? "Enter regex here"
      : "Enter text here";

  return (
    <TextArea
      focusOnMount={
        context.expressionEditorState.selectedItem?.id === expression.id
      }
      readOnly={readOnly}
      intent={
        context.expressionIdToIntent?.[expression.id] ??
        (getStringExpressionInAListOrComparisonExpressionWarning({
          context,
          parentExpression,
          expression,
        })
          ? "warning"
          : "neutral")
      }
      placeholder={placeholder}
      value={expression.value}
      setValue={(newValue) => {
        setExpression({
          ...expression,
          value: newValue,
        });
      }}
      shouldStack={shouldStack}
      dragHandle={dragHandle}
      optionsButton={optionsButton}
      error={
        warningText ? (
          <p className="whitespace-nowrap">{warningText}</p>
        ) : undefined
      }
      errorIntent="warning"
      errorPosition="top-end"
      onSplit={
        !context.disableStringListSplitAndWarnings &&
        parentExpression?.type === "ListExpression" &&
        setParentExpression
          ? (lines): void => {
              const { items } = parentExpression;

              const index = items.findIndex(
                (item) => item?.id === expression.id
              );

              if (index < 0) {
                return;
              }

              setParentExpression({
                ...parentExpression,
                items: [
                  ...items.slice(0, index),
                  ...lines.map((line) => getStringExpression(line)),
                  ...items.slice(index + 1),
                ],
              });
            }
          : undefined
      }
      onClick={
        !readOnly
          ? (event) => {
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: { type: "expression", id: expression.id },
              });
              event.stopPropagation();
            }
          : undefined
      }
      onBlur={() =>
        context.setExpressionEditorState({
          ...context.expressionEditorState,
          selectedItem: null,
        })
      }
      className={twJoin(
        isExpressionSelected && !disablePanelOnSelect
          ? "border-intent-primary shadow-inputs"
          : !disablePanelOnSelect
            ? "hover:border-intent-primary"
            : undefined,
        className
      )}
    />
  );
}

export function getStringExpressionInAListOrComparisonExpressionWarning({
  context,
  parentExpression,
  expression,
}: {
  context: ExpressionControlContext;
  parentExpression?: Expression | null;
  expression: Expression | null;
}): string | null {
  if (
    context.disableStringListSplitAndWarnings ||
    context.expressionEditorState.selectedItem?.id === expression?.id ||
    expression?.metadata?.note ||
    expression?.type !== "StringExpression" ||
    (parentExpression?.type !== "ListExpression" &&
      parentExpression?.type !== "ComparisonExpression")
  ) {
    return null;
  }
  if (!expression.value) {
    return "Empty string";
  }
  if (expression.value.trimEnd() !== expression.value) {
    return "Trailing whitespace";
  }
  if (expression.value.trimStart() !== expression.value) {
    return "Leading whitespace";
  }
  return null;
}

function AIMenu({
  rephrase,
  loading,
}: {
  rephrase: (action: RephraseAction) => void;
  loading: boolean;
}): React.ReactElement | null {
  return (
    <div className="-my-1 h-[26px]">
      <Menu
        items={Object.values(RephraseAction).map<MenuItem>((action) => ({
          title: toWords(action)
            .map((word, index) =>
              index === 0 ? toStartCase(word) : word.toLowerCase()
            )
            .join(" "),
          icon: getActionIcon(action),
          intent: "neutral" as Intent,
          onClick: () => rephrase(action),
        }))}
        button={loading ? <Spinner size={14} /> : <Sparkle size={14} />}
        itemsClassName="w-52"
        className="h-[26px] w-[26px]"
      />
    </div>
  );
}

function getActionIcon(action: RephraseAction): React.ReactElement {
  switch (action) {
    case RephraseAction.ImproveWriting:
      return <MagicWand size={14} />;
    case RephraseAction.FixSpellingAndGrammar:
      return <Check size={14} weight="bold" />;
    case RephraseAction.MakeLonger:
      return <SortAscending size={14} weight="regular" />;
    case RephraseAction.MakeShorter:
      return <SortDescending size={14} weight="regular" />;

    default:
      return <Sparkle size={14} />;
  }
}
