import {
  ApplicationExpression,
  EventTypeMap,
  Expression,
  ListExpression,
  Schema,
  SplitMap,
  uniqueId,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import {
  AnalyticsSchema,
  cloneObject,
  collectSplitAndEventTypeIds,
  copyImplementation,
  formatTypeSchemaName,
} from "@hypertune/shared-internal";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import isPrimitiveExpression from "@hypertune/shared-internal/src/expression/isPrimitiveExpression";
import getDefaultExpression from "@hypertune/shared-internal/src/expression/getDefaultExpression";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import { getListItemFieldPath } from "@hypertune/shared-internal/src/expression/fieldPath";
import createApplication from "../../../../lib/expression/createApplication";
import isComparisonOperand from "../../../../lib/expression/isComparisonOperand";
import isEmbeddedListExpression from "../../../../lib/expression/isEmbeddedListExpression";
import {
  small,
  normal,
  singlePanelInnerHeight,
  darkGreyHex,
  plusSymbol,
} from "../../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  SelectedItem,
} from "../../../../lib/types";
import Button from "../../../../components/buttons/Button";
import ExpressionControl from "./ExpressionControl";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import getListFieldLabelExpression from "../../../../lib/expression/getListFieldLabelExpression";

import SortableList from "../../../../components/SortableList";

export default function ListExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  lift,
  parentExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: ListExpression;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  parentExpression: Expression | null;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const allowDuplicates =
    !isComparisonOperand(parentExpression, expression) ||
    expression.valueType.itemValueType.type !== "EnumValueType";
  const showAddButton =
    !readOnly &&
    (allowDuplicates ||
      expression.valueType.itemValueType.type !== "EnumValueType" ||
      expression.items.length <
        Object.keys(
          context.commitContext.schema.enums[
            expression.valueType.itemValueType.enumTypeName
          ].values
        ).length);
  const disableAICompletions =
    parentExpression?.type !== "StringConcatExpression";

  const allowedValuesSet = new Set(
    expression.valueType.itemValueType.type === "EnumValueType"
      ? Object.keys(
          context.commitContext.schema.enums[
            expression.valueType.itemValueType.enumTypeName
          ].values
        )
      : []
  );
  expression.items.forEach((item) => {
    if (item?.type === "EnumExpression") {
      allowedValuesSet.delete(item.value);
    }
  });

  const addNewValue = getNewListItemValueFunction(
    context.commitContext.schema,
    variables,
    expression,
    setExpression,
    (newSelectedItem) =>
      context.setExpressionEditorState({
        ...context.expressionEditorState,
        selectedItem: newSelectedItem,
      }),
    allowDuplicates
  );

  const childValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType.itemValueType)
    : { type: "ErrorValueTypeConstraint" };

  // If every entry is a single line expression, use a smaller gap.
  const itemGap = expression.items.every(
    (item) =>
      !item ||
      isPrimitiveExpression(item) ||
      item.type === "VariableExpression" ||
      item.type === "GetFieldExpression"
  )
    ? small
    : normal;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: normal }}>
      {expression.items.length > 0 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: itemGap }}>
          <SortableList<Expression | { id: string; type: "NullListExpression" }>
            disabled={readOnly || expression.items.length === 1}
            list={expression.items.map((item) =>
              item === null
                ? { type: "NullListExpression", id: uniqueId() }
                : item
            )}
            setList={(newItems) =>
              setExpression({
                ...expression,
                items: newItems.map((item) =>
                  item.type === "NullListExpression" ? null : item
                ),
              })
            }
            renderItemComponent={({ index, dragHandle }) => {
              const item = expression.items[index];
              // eslint-disable-next-line func-style
              const setChildExpression = (
                newExpression: Expression | null
              ): void => {
                // Expressions should be inserted using the options menu
                if (newExpression === null) {
                  setExpression({
                    ...expression,
                    items: [
                      ...expression.items.slice(0, index),
                      ...expression.items.slice(index + 1),
                    ],
                  });
                  return;
                }

                setExpression({
                  ...expression,
                  items: [
                    ...expression.items.slice(0, index),
                    newExpression,
                    ...expression.items.slice(index + 1),
                  ],
                });
              };
              return (
                <ExpressionControl
                  key={`list-expression-item-${expression.id}-${item ? item.id : index.toString()}`}
                  context={{
                    ...context,
                    disableAICompletions,
                    fullFieldPath: getListItemFieldPath(
                      context.fullFieldPath,
                      index
                    ),
                  }}
                  variables={variables}
                  setVariableName={setVariableName}
                  valueTypeConstraint={childValueTypeConstraint}
                  expression={item}
                  setExpression={setChildExpression}
                  lift={getListExpressionLiftFunction({
                    index,
                    variables,
                    expression,
                    setExpression,
                    lift,
                    parentExpression,
                    setExpressionEditorSelectedItem: (newSelectedItem) =>
                      context.setExpressionEditorState({
                        ...context.expressionEditorState,
                        selectedItem: newSelectedItem,
                      }),
                  })}
                  parentExpression={expression}
                  setParentExpression={setExpression}
                  includeExpressionOption={({
                    expressionOption,
                    expressionOptionParent,
                  }) => {
                    if (
                      !allowDuplicates &&
                      expression === expressionOptionParent &&
                      expressionOption.type === "EnumExpression"
                    ) {
                      return (
                        allowedValuesSet.has(expressionOption.value) ||
                        (item?.type === "EnumExpression" &&
                          item.value === expressionOption.value)
                      );
                    }
                    return includeExpressionOption({
                      expressionOption,
                      expressionOptionParent,
                    });
                  }}
                  dragHandle={dragHandle}
                />
              );
            }}
          />
        </div>
      ) : null}
      {showAddButton ? (
        <Button
          intent="primary"
          weight="minimal"
          className="border-intent-primary/40"
          text="Value"
          icon={plusSymbol}
          onClick={() => {
            addNewValue();
          }}
        />
      ) : expression.items?.length === 0 ? (
        <div
          style={{
            height: singlePanelInnerHeight,
            display: "flex",
            alignItems: "center",
            color: darkGreyHex,
          }}
        >
          (empty list)
        </div>
      ) : null}
    </div>
  );
}

export function getListExpressionLiftFunction({
  index,
  variables,
  expression,
  setExpression,
  lift,
  parentExpression,
  setExpressionEditorSelectedItem,
}: {
  index: number;
  variables: VariableMap;
  expression: ListExpression;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  parentExpression: Expression | null;
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void;
}): LiftFunction {
  return (child): void => {
    function replaceArgument(
      variable: VariableExpression | ApplicationExpression
    ): ListExpression {
      const newExpression: ListExpression = {
        ...expression,
        items: [
          ...expression.items.slice(0, index),
          child.replaceArgument(variable),
          ...expression.items.slice(index + 1),
        ],
      };
      return newExpression;
    }

    if (isEmbeddedListExpression(parentExpression, expression)) {
      // We never lift directly in an embedded list; we let the
      // parent lift
      lift({
        argument: child.argument,
        replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
        replaceArgument,
        newVariableName: child.newVariableName,
        isNew: child.isNew,
        keepInObjectField: child.keepInObjectField,
      });
      return;
    }

    const applicationExpression = createApplication({
      variables,
      rawArgument: child.argument,
      replacedVariableIdToNewVariable: child.replacedVariableIdToNewVariable,
      valueType: expression.valueType,
      replaceArgument,
      newVariableName: child.newVariableName,
      setExpressionEditorSelectedItem,
    });
    setExpression(applicationExpression);
  };
}

// eslint-disable-next-line max-params
export function getNewListItemValueFunction(
  schema: Schema,
  variables: VariableMap,
  expression: ListExpression,
  setExpression: (newExpression: Expression | null) => void,
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void,
  allowDuplicates: boolean | undefined
): () => string {
  return function newValue(): string {
    const { itemValueType } = expression.valueType;
    const newItem = getDefaultExpression(
      schema,
      variables,
      getConstraintFromValueType(itemValueType),
      new Set()
    );
    if (!allowDuplicates && newItem && newItem.type === "EnumExpression") {
      const existingValues = new Set(
        expression.items.flatMap((item) =>
          item?.type === "EnumExpression" ? [item.value] : []
        )
      );
      newItem.value =
        Object.keys(schema.enums[newItem.valueType.enumTypeName].values).find(
          (value) => !existingValues.has(value)
        ) ?? newItem.value;
    }
    if (newItem) {
      setExpressionEditorSelectedItem({ type: "expression", id: newItem.id });
    }
    setExpression({
      ...expression,
      items: [...expression.items, newItem],
    });
    // We add the new item at the end of the list,
    // so the previous list length will be its index
    return (expression.items.length + 1).toString() || "";
  };
}

export function duplicateItemInListExpression(
  schema: Schema,
  expressionItems: (Expression | null)[],
  expression: Expression,
  analyticsSchema: Omit<AnalyticsSchema, "features">
): {
  newExpressionItems: (Expression | null)[];
  newSplits: SplitMap;
  newEventTypes: EventTypeMap;
  newSchema: Schema;
} {
  const index = expressionItems.findIndex((item) => item?.id === expression.id);
  const { eventTypeIds, splitIds } = collectSplitAndEventTypeIds(expression);
  const {
    expression: newExpression,
    splits: newSplits,
    eventTypes: rawEventTypes,
  } = copyImplementation(
    {
      expression,
      features: {},
      splits: Object.fromEntries(
        Object.entries(analyticsSchema.splits).filter(([splitId]) =>
          splitIds.has(splitId)
        )
      ),
      eventTypes: Object.fromEntries(
        Object.entries(analyticsSchema.eventTypes).filter(([eventTypeId]) =>
          eventTypeIds.has(eventTypeId)
        )
      ),
    },
    {
      namePrefix: copyOfPrefix,
      skipVariableCopy: true,
    }
  );
  const labelExpression = getListFieldLabelExpression(newExpression);
  if (labelExpression) {
    labelExpression.value = `${copyOfPrefix}${labelExpression.value}`;
  }
  const newEventTypes = Object.fromEntries(
    Object.entries(rawEventTypes).map(([id, eventType]) => [
      id,
      { ...eventType, name: formatTypeSchemaName(eventType.name) },
    ])
  );

  return {
    newExpressionItems: [
      ...expressionItems.slice(0, index + 1),
      newExpression,
      ...expressionItems.slice(index + 1),
    ],
    newSplits,
    newEventTypes,
    newSchema: Object.values(newEventTypes).reduce(
      (currentSchema, eventType) =>
        cloneObject(
          currentSchema,
          eventType.name.replace(copyOfPrefixFormatted, ""),
          eventType.name,
          "event"
        ),
      schema
    ),
  };
}

const copyOfPrefix = "Copy of ";
const copyOfPrefixFormatted = formatTypeSchemaName(copyOfPrefix);
