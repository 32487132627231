import { rootObjectTypeName } from "@hypertune/shared-internal";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setObjectAddFieldModalState,
  setNewVariableModal,
} from "../projectSlice";
import Tooltip from "../../../components/tooltips/Tooltip";
import { useHypertune } from "../../../generated/hypertune.react";
import Menu from "../../../components/Menu";
import Flag from "../../../components/icons/Flag";
import Variable from "../../../components/icons/Variable";
import PlusButton from "../../../components/buttons/PlusButton";

export default function NewFlagButton({
  defaultFieldPath,
}: {
  defaultFieldPath: string[];
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const content = hypertune.content();
  const dispatch = useAppDispatch();
  const showNewFlagModal = useShowNewFlagModal(defaultFieldPath);

  if (!showNewFlagModal) {
    return null;
  }

  return (
    <Tooltip id="logic" step={1} allSteps={content.logic().tooltips()}>
      {hypertune.enableTopLevelVariableCreation({ fallback: false }) ? (
        <Menu
          button={<Plus weight="bold" size={12} />}
          items={[
            {
              title: "Flag",
              icon: <Flag size={12} />,
              intent: "neutral",
              onClick: showNewFlagModal,
            },
            {
              title: "Variable",
              icon: <Variable size={12} />,
              intent: "neutral",
              onClick: () =>
                dispatch(setNewVariableModal({ defaultFieldPath })),
            },
          ]}
          className="h-[26px] w-[26px] border hover:bg-intent-neutral/10"
        />
      ) : (
        <PlusButton onClick={showNewFlagModal} />
      )}
    </Tooltip>
  );
}

export function useShowNewFlagModal(
  defaultFieldPath: string[]
): null | (() => void) {
  const dispatch = useAppDispatch();
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );

  if (!expression) {
    return null;
  }
  return () =>
    dispatch(
      setObjectAddFieldModalState({
        objectTypeName: rootObjectTypeName,
        fieldPosition: "first",
        entity: {
          name: "flag",
          parentFieldPath: defaultFieldPath.join(fieldPathSeparator),
        },
      })
    );
}
