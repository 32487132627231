import { Permissions } from "@hypertune/sdk/src/shared";
import React from "react";
import GroupOrUserSelector from "../../GroupOrUserSelector";
import DeleteButton from "../../../../components/buttons/DeleteButton";

export default function PermissionsList({
  isVisible,
  groupIdToDisplayName,
  userIdToDisplayName,
  permissions,
  setPermissions,
  style,
}: {
  isVisible: boolean;
  groupIdToDisplayName: { [userId: string]: string };
  userIdToDisplayName: { [userId: string]: string };
  permissions: Permissions;
  setPermissions?: (newPermissions: Permissions) => void;
  style?: React.CSSProperties;
}): React.ReactElement {
  const groupIds = Object.keys(permissions.group);
  const userIds = Object.keys(permissions.user);

  return (
    <div
      style={{
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        alignItems: "stretch",
        ...style,
      }}
    >
      {groupIds.map((groupId) => {
        return (
          <Item
            key={`group${groupId}`}
            label={groupIdToDisplayName[groupId] ?? `Group ${groupId}`}
            onDelete={
              setPermissions
                ? () => {
                    const { [groupId]: _, ...group } = permissions.group;
                    setPermissions({ ...permissions, group });
                  }
                : undefined
            }
          />
        );
      })}
      {userIds.map((userId) => {
        return (
          <Item
            key={`user${userId}`}
            label={userIdToDisplayName[userId] ?? `User ${userId}`}
            onDelete={
              setPermissions
                ? () => {
                    const { [userId]: _, ...user } = permissions.user;
                    setPermissions({ ...permissions, user });
                  }
                : undefined
            }
          />
        );
      })}
      {setPermissions ? (
        <GroupOrUserSelector
          style={{ marginBottom: 12 }}
          groupIdToDisplayName={groupIdToDisplayName}
          userIdToDisplayName={userIdToDisplayName}
          selectedId={null}
          setSelectedId={(selectedId) => {
            if (selectedId.type === "group") {
              setPermissions({
                ...permissions,
                group: {
                  ...permissions.group,
                  [selectedId.groupId]: { write: "allow" },
                },
              });
              return;
            }

            setPermissions({
              ...permissions,
              user: {
                ...permissions.user,
                [selectedId.userId]: { write: "allow" },
              },
            });
          }}
          placeholder="Allow a group or user to edit this expression"
        />
      ) : null}
    </div>
  );
}

function Item({
  label,
  onDelete,
}: {
  label: string;
  onDelete?: () => void;
}): React.ReactElement {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 12,
      }}
    >
      {onDelete ? (
        <DeleteButton size="x-small" className="mr-[6px]" onClick={onDelete} />
      ) : null}
      <div>{label}</div>
    </div>
  );
}
