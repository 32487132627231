import {
  ApplicationExpression,
  Expression,
  LogEventExpression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../../lib/expression/createApplication";
import getTextLabel from "../../../../lib/generic/getTextLabel";
import {
  liftPermissionsDeniedErrorMessage,
  small,
} from "../../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import IdSelector from "../../IdSelector";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function LogEventExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: LogEventExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";

  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });

  return (
    <>
      <div style={{ height: small }} />
      <LabeledExpressionControlList
        useArrow={false}
        items={[
          {
            type: LabeledExpressionControlListItemControlType,
            label: getTextLabel("Event Type"),
            intent,
            options: [],
            expressionControl: (
              <IdSelector
                intent={
                  context.expressionIdToIntent?.[
                    `${expression.id}eventObjectTypeName`
                  ]
                }
                showGoToArrow
                context={context.commitContext}
                source="eventTypes"
                selectedId={expression.eventObjectTypeName}
                setSelectedId={(newEventObjectTypeName: string | null) => {
                  setExpression({
                    ...expression,
                    eventObjectTypeName: newEventObjectTypeName,
                    eventTypeId: null, // Set to null as this will be populated in fixAndSimplify
                  });
                }}
                readOnly={readOnly}
                variant="with-error"
              />
            ),
          },
          {
            type: LabeledExpressionControlListItemControlType,
            label: getTextLabel("Payload"),
            intent,
            options: [],
            expressionControl: (
              <ExpressionControl
                context={context}
                variables={variables}
                setVariableName={setVariableName}
                valueTypeConstraint={
                  expression.eventObjectTypeName &&
                  context.commitContext.schema.objects[
                    expression.eventObjectTypeName
                  ]
                    ? {
                        type: "ObjectValueTypeConstraint",
                        objectTypeName: expression.eventObjectTypeName,
                      }
                    : { type: "ErrorValueTypeConstraint" }
                }
                expression={expression.eventPayload}
                setExpression={(newExpression: Expression | null): void =>
                  setExpression({
                    ...expression,
                    eventPayload: newExpression,
                  })
                }
                lift={(child): void => {
                  if (readOnly) {
                    // eslint-disable-next-line no-alert
                    alert(liftPermissionsDeniedErrorMessage);
                    return;
                  }
                  function replaceArgument(
                    variable: VariableExpression | ApplicationExpression
                  ): LogEventExpression {
                    const newExpression: LogEventExpression = {
                      ...expression,
                      eventPayload: child.replaceArgument(variable),
                    };
                    return newExpression;
                  }
                  const applicationExpression = createApplication({
                    variables,
                    rawArgument: child.argument,
                    replacedVariableIdToNewVariable:
                      child.replacedVariableIdToNewVariable,
                    valueType: expression.valueType,
                    replaceArgument,
                    newVariableName: child.newVariableName,
                    setExpressionEditorSelectedItem: (newSelectedItem) =>
                      context.setExpressionEditorState({
                        ...context.expressionEditorState,
                        selectedItem: newSelectedItem,
                      }),
                  });
                  setExpression(applicationExpression);
                }}
                parentExpression={expression}
                setParentExpression={setExpression}
                includeExpressionOption={includeExpressionOption}
                disablePanelOnSelect={isExpressionSelectabilityEnabled}
              />
            ),
          },
          ...(expression.unitId &&
          expression.unitId.type === "StringExpression" &&
          !expression.unitId.value
            ? []
            : [
                {
                  type: LabeledExpressionControlListItemControlType,
                  label: getTextLabel("Unit ID (Deprecated)"),
                  intent,
                  options: [],
                  expressionControl: (
                    <ExpressionControl
                      context={context}
                      variables={variables}
                      setVariableName={setVariableName}
                      valueTypeConstraint={{
                        type: "StringValueTypeConstraint",
                      }}
                      expression={expression.unitId}
                      setExpression={(newExpression: Expression | null): void =>
                        setExpression({
                          ...expression,
                          unitId: newExpression,
                        })
                      }
                      lift={(child): void => {
                        if (readOnly) {
                          // eslint-disable-next-line no-alert
                          alert(liftPermissionsDeniedErrorMessage);
                          return;
                        }
                        function replaceArgument(
                          variable: VariableExpression | ApplicationExpression
                        ): LogEventExpression {
                          const newExpression: LogEventExpression = {
                            ...expression,
                            unitId: child.replaceArgument(variable),
                          };
                          return newExpression;
                        }
                        const applicationExpression = createApplication({
                          variables,
                          rawArgument: child.argument,
                          replacedVariableIdToNewVariable:
                            child.replacedVariableIdToNewVariable,
                          valueType: expression.valueType,
                          replaceArgument,
                          newVariableName: child.newVariableName,
                          setExpressionEditorSelectedItem: (newSelectedItem) =>
                            context.setExpressionEditorState({
                              ...context.expressionEditorState,
                              selectedItem: newSelectedItem,
                            }),
                        });
                        setExpression(applicationExpression);
                      }}
                      parentExpression={expression}
                      setParentExpression={setExpression}
                      includeExpressionOption={includeExpressionOption}
                      disablePanelOnSelect={isExpressionSelectabilityEnabled}
                    />
                  ),
                },
              ]),
        ]}
        context={context}
        expressionIds={
          [
            null,
            expression.eventPayload?.id ?? "",
            expression.unitId &&
            expression.unitId.type === "StringExpression" &&
            !expression.unitId.value
              ? undefined
              : (expression.unitId?.id ?? ""),
          ].filter((id) => id !== undefined) as string[]
        }
      />
    </>
  );
}
