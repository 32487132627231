import {
  ApplicationExpression,
  Expression,
  GetUrlQueryParameterExpression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import createApplication from "../../../../lib/expression/createApplication";
import getTextLabel from "../../../../lib/generic/getTextLabel";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import LabeledExpressionControlList, {
  LabeledExpressionControlListItemControlType,
} from "./LabeledExpressionControlList";
import { liftPermissionsDeniedErrorMessage } from "../../../../lib/constants";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function GetUrlQueryParameterExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  setExpression,
  includeExpressionOption,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: GetUrlQueryParameterExpression;
  setExpression: (newExpression: Expression | null) => void;
  includeExpressionOption: IncludeExpressionOptionFunction;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";
  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });

  return (
    <LabeledExpressionControlList
      useArrow
      items={[
        {
          type: LabeledExpressionControlListItemControlType,
          label: getTextLabel("URL"),
          intent,
          options: [],
          expressionControl: (
            <ExpressionControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              valueTypeConstraint={{ type: "StringValueTypeConstraint" }}
              expression={expression.url}
              setExpression={(newExpression: Expression | null): void =>
                setExpression({ ...expression, url: newExpression })
              }
              lift={(child): void => {
                if (readOnly) {
                  // eslint-disable-next-line no-alert
                  alert(liftPermissionsDeniedErrorMessage);
                  return;
                }
                function replaceArgument(
                  variable: VariableExpression | ApplicationExpression
                ): GetUrlQueryParameterExpression {
                  const newExpression: GetUrlQueryParameterExpression = {
                    ...expression,
                    url: child.replaceArgument(variable),
                  };
                  return newExpression;
                }
                const applicationExpression = createApplication({
                  variables,
                  rawArgument: child.argument,
                  replacedVariableIdToNewVariable:
                    child.replacedVariableIdToNewVariable,
                  valueType: expression.valueType,
                  replaceArgument,
                  newVariableName: child.newVariableName,
                  setExpressionEditorSelectedItem: (newSelectedItem) =>
                    context.setExpressionEditorState({
                      ...context.expressionEditorState,
                      selectedItem: newSelectedItem,
                    }),
                });
                setExpression(applicationExpression);
              }}
              parentExpression={expression}
              setParentExpression={setExpression}
              includeExpressionOption={includeExpressionOption}
              disablePanelOnSelect={isExpressionSelectabilityEnabled}
            />
          ),
        },
        {
          type: LabeledExpressionControlListItemControlType,
          label: getTextLabel("Param Name"),
          intent,
          options: [],
          expressionControl: (
            <ExpressionControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              valueTypeConstraint={{ type: "StringValueTypeConstraint" }}
              expression={expression.queryParameterName}
              setExpression={(newExpression: Expression | null): void =>
                setExpression({
                  ...expression,
                  queryParameterName: newExpression,
                })
              }
              lift={(child): void => {
                if (readOnly) {
                  // eslint-disable-next-line no-alert
                  alert(liftPermissionsDeniedErrorMessage);
                  return;
                }
                function replaceArgument(
                  variable: VariableExpression | ApplicationExpression
                ): GetUrlQueryParameterExpression {
                  const newExpression: GetUrlQueryParameterExpression = {
                    ...expression,
                    queryParameterName: child.replaceArgument(variable),
                  };
                  return newExpression;
                }
                const applicationExpression = createApplication({
                  variables,
                  rawArgument: child.argument,
                  replacedVariableIdToNewVariable:
                    child.replacedVariableIdToNewVariable,
                  valueType: expression.valueType,
                  replaceArgument,
                  newVariableName: child.newVariableName,
                  setExpressionEditorSelectedItem: (newSelectedItem) =>
                    context.setExpressionEditorState({
                      ...context.expressionEditorState,
                      selectedItem: newSelectedItem,
                    }),
                });
                setExpression(applicationExpression);
              }}
              parentExpression={expression}
              setParentExpression={setExpression}
              includeExpressionOption={includeExpressionOption}
              disablePanelOnSelect={isExpressionSelectabilityEnabled}
            />
          ),
        },
      ]}
      context={context}
      expressionIds={[
        expression.url?.id ?? "",
        expression.queryParameterName?.id ?? "",
      ]}
    />
  );
}
