import { Intent } from "../../../components/intent";
import { ExpressionControlContext } from "../../../lib/types";
import Panel from "../logic/expression/Panel";

export default function EmptyPanel({
  intent,
  className,
  context,
}: {
  intent: Intent;
  className?: string;
  context: ExpressionControlContext;
}): React.ReactElement | null {
  return (
    <div className={className}>
      <Panel
        header={null}
        message={null}
        shouldStack={false}
        intent={intent}
        expressionId=""
        disablePanelOnSelect={false}
        context={context}
      >
        None
      </Panel>
    </div>
  );
}
