import {
  Expression,
  Schema,
  SwitchExpression,
  ValueType,
  getBooleanExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import getComparisonExpression from "./getComparisonExpression";
import { VariableMap } from "./types";
import { BaseExpressionForValueTypeOptions } from "./getExpressionOptionGroups";
import getDefaultExpression from "./getDefaultExpression";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";

// eslint-disable-next-line max-params
export default function getIfExpression(
  schema: Schema,
  variables: VariableMap,
  valueType: ValueType,
  options?: BaseExpressionForValueTypeOptions,
  defaultValue?: Expression
): SwitchExpression {
  const trueExpression = getBooleanExpression(true);
  const initialCase = {
    id: uniqueId(),
    when: getComparisonExpression(),
    then: getDefaultExpression(
      schema,
      variables,
      getConstraintFromValueType(valueType),
      new Set(),
      options
    ),
  };
  const defaultExpression =
    defaultValue ??
    (valueType.type === "BooleanValueType"
      ? getBooleanExpression(false)
      : getDefaultExpression(
          schema,
          variables,
          getConstraintFromValueType(valueType),
          new Set(),
          options
        ));

  const ifElseExpression: SwitchExpression = {
    id: uniqueId(),
    type: "SwitchExpression",
    valueType,
    control: trueExpression,
    cases: [initialCase],
    default: defaultExpression,
  };
  return ifElseExpression;
}
