import {
  asError,
  ObjectSchema,
  Schema,
  ValueType,
} from "@hypertune/sdk/src/shared";
import { ArrowRight, Plus } from "@phosphor-icons/react";
import {
  formatFieldSchemaName,
  rootObjectTypeNameFromSchema,
} from "@hypertune/shared-internal";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { useCallback } from "react";
import MutableText from "../../../../../components/input/MutableText";
import { boldFontWeight, whiteHex } from "../../../../../lib/constants";
import Button from "../../../../../components/buttons/Button";
import Label from "../../../../../components/Label";
import Card from "../../../../../components/Card";
import TypeIcon, {
  TypeIconType,
} from "../../../../../components/icons/TypeIcon";
import SearchInput from "../../../../../components/SearchInput";
import matchesSearch from "../../../../../lib/generic/matchesSearch";
import { SelectedType, useRenameObjectField } from "../../schemaHooks";
import { objectFieldNameError, showSchemaNameError } from "../SchemaNameError";
import { unrollValueType } from "./ValueTypeSelector";
import Tooltip from "../../../../../components/tooltips/Tooltip";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  setDraftCommitSchema,
  setObjectAddFieldModalState,
} from "../../../projectSlice";
import { useHypertune } from "../../../../../generated/hypertune.react";
import SortableObjectList from "../../../../../components/SortableObjectList";

export default function ObjectEditor({
  readOnly,
  schema,
  objectTypeName,
  selectedType,
  setSelectedType,
  setErrorMessage,
  fieldsAndValuesSearchText,
  setFieldsAndValuesSearchText,
}: {
  readOnly: boolean;
  schema: Schema;
  objectTypeName: string;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  fieldsAndValuesSearchText: string;
  setFieldsAndValuesSearchText: (newSearchText: string) => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const content = useHypertune().content();
  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);

  const setObjectFields = useCallback(
    (newFields: ObjectSchema["fields"]) => {
      dispatch(
        setDraftCommitSchema({
          ...schema,
          objects: {
            ...schema.objects,
            [objectTypeName]: {
              ...schema.objects[objectTypeName],
              fields: newFields,
            },
          },
        })
      );
    },
    [dispatch, objectTypeName, schema]
  );

  return (
    <>
      <div className="mb-[6px] flex min-w-[325px] flex-wrap items-center justify-between gap-2">
        <Label className="whitespace-nowrap" type="title1">
          Fields
        </Label>
        <div className="flex flex-row gap-2">
          <SearchInput
            key={`${objectTypeName}-search`}
            searchText={fieldsAndValuesSearchText}
            setSearchText={setFieldsAndValuesSearchText}
            style={{ backgroundColor: whiteHex }}
          />
          {!readOnly && (
            <Button
              disabled={readOnly}
              intent="primary"
              weight="filled"
              size="large"
              icon={<Plus weight="regular" color="white" />}
              text="Add"
              onClick={() =>
                dispatch(
                  setObjectAddFieldModalState({
                    objectTypeName,
                    fieldPosition: "first",
                    entity: { name: "field" },
                  })
                )
              }
            />
          )}
        </div>
      </div>

      <div className="flex w-full flex-col items-stretch gap-4">
        <SortableObjectList
          disabled={readOnly}
          object={schema.objects[objectTypeName].fields}
          setObject={setObjectFields}
          includeId={(fieldName) =>
            matchesSearch(fieldsAndValuesSearchText, [fieldName])
          }
          renderItemComponent={({ id: fieldName, dragHandle }, index) => {
            const card = (
              <FieldCard
                readOnly={readOnly}
                schema={schema}
                objectTypeName={objectTypeName}
                fieldName={fieldName}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                setErrorMessage={setErrorMessage}
                dragHandle={dragHandle}
              />
            );

            if (objectTypeName === rootObjectTypeName && index === 0) {
              return (
                <Tooltip
                  id="schema"
                  step={3}
                  allSteps={content.schema().tooltips()}
                  placement="bottom-end"
                  topOffset={-59}
                  topArrowOffset={-18}
                  onNext={() =>
                    setSelectedType({
                      ...selectedType,
                      selectedChildName: fieldName,
                    })
                  }
                >
                  {card}
                </Tooltip>
              );
            }
            return card;
          }}
        />
      </div>
      {!readOnly && (
        <div className="flex flex-row">
          <div className="bg-white">
            <Button
              disabled={readOnly}
              intent="neutral"
              weight="outlined"
              size="large"
              icon={<Plus weight="regular" />}
              text="Add"
              onClick={() =>
                dispatch(
                  setObjectAddFieldModalState({
                    objectTypeName,
                    fieldPosition: "last",
                    entity: { name: "field" },
                  })
                )
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

function FieldCard({
  readOnly,
  schema,
  objectTypeName,
  fieldName,
  selectedType,
  setSelectedType,
  setErrorMessage,
  dragHandle,
}: {
  readOnly: boolean;
  schema: Schema;
  objectTypeName: string;
  fieldName: string;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  dragHandle: React.ReactNode;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const renameObjectField = useRenameObjectField();

  const field = schema.objects[objectTypeName].fields[fieldName];
  const unwrappedValueType = unwrapValueType(field.valueType);

  return (
    <Card
      key={fieldName}
      className="min-w-[325px] gap-2 pl-[10px]"
      layout="horizontal-with-icon"
      isSelected={
        selectedType?.name === objectTypeName &&
        selectedType?.selectedChildName === fieldName
      }
      onMouseDown={(event) => {
        event.stopPropagation();
        setSelectedType({
          ...selectedType,
          selectedChildName: fieldName,
        });
      }}
    >
      {dragHandle}
      <div className="flex flex-row items-center gap-[10px]">
        <TypeIcon
          type={valueTypeToTypeIconType(schema, field.valueType)}
          size="large"
        />
        <div className="-ml-[2px] -mt-[2px] flex max-w-full flex-col items-start overflow-hidden px-[2px] pt-[2px]">
          <MutableText
            readOnly={readOnly}
            text={toStartCase(fieldName)}
            setText={async (newFieldName) => {
              try {
                await renameObjectField(
                  objectTypeName,
                  fieldName,
                  newFieldName
                );
                setSelectedType({
                  ...selectedType,
                  selectedChildName: formatFieldSchemaName(newFieldName),
                });
              } catch (error) {
                setErrorMessage(asError(error).message);
              }
            }}
            showPencil={false}
            stopClickPropagation={false}
            style={{
              lineHeight: "16px",
              fontWeight: boldFontWeight,
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
            minWidth={0}
            className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
            confirmModalContent={content.schema().renameConfirmation().get()}
            confirmModalVariables={{ entityName: "field" }}
            hasError={(newName) => {
              const newFormattedName = formatFieldSchemaName(newName);
              if (newFormattedName === fieldName) {
                return null;
              }
              return objectFieldNameError(
                schema,
                "field",
                objectTypeName,
                newFormattedName
              );
            }}
            showError={showSchemaNameError}
          />
          <Label
            type="small-body"
            className="ml-[3px] whitespace-nowrap text-tx-muted"
          >
            {valueTypeToTypeDescription(schema, field.valueType)}
          </Label>
        </div>
      </div>

      {((unwrappedValueType.type === "ObjectValueType" &&
        schema.objects[unwrappedValueType.objectTypeName]) ||
        unwrappedValueType.type === "EnumValueType") && (
        <Button
          text="Go to type"
          onClick={() => {
            if (unwrappedValueType.type === "ObjectValueType") {
              setSelectedType({
                type:
                  schema.objects[unwrappedValueType.objectTypeName].role ===
                  "input"
                    ? "input"
                    : "object",
                name: unwrappedValueType.objectTypeName,
                selectedChildName: null,
              });
            }
            if (unwrappedValueType.type === "EnumValueType") {
              setSelectedType({
                type: "enum",
                name: unwrappedValueType.enumTypeName,
                selectedChildName: null,
              });
            }
          }}
          iconEnd={<ArrowRight weight="regular" />}
          className="mr-2 opacity-0 group-hover:opacity-100"
        />
      )}
    </Card>
  );
}

export function valueTypeToTypeIconType(
  schema: Schema,
  valueType: ValueType
): TypeIconType {
  switch (valueType.type) {
    case "BooleanValueType":
      return "boolean";
    case "StringValueType":
      return "string";
    case "IntValueType":
      return "int";
    case "FloatValueType":
      return "float";
    case "EnumValueType":
      return "enum";
    case "ObjectValueType":
      if (schema.objects[valueType.objectTypeName]?.role === "input") {
        return "input";
      }
      if (schema.objects[valueType.objectTypeName]?.role === "event") {
        return "event";
      }
      return "object";
    case "UnionValueType":
      return "object";
    case "ListValueType":
      return "list";
    case "VoidValueType":
      return "event";

    case "FunctionValueType":
      return valueTypeToTypeIconType(schema, valueType.returnValueType);
    default:
      throw new Error(`unexpected value type: ${valueType.type}`);
  }
}

function valueTypeToTypeDescription(
  schema: Schema,
  valueType: ValueType
): string {
  switch (valueType.type) {
    case "BooleanValueType":
      return "Boolean";
    case "StringValueType":
      return "String";
    case "IntValueType":
      return "Integer";
    case "FloatValueType":
      return "Float";
    case "EnumValueType":
      return "Enum";
    case "ObjectValueType":
      if (schema.objects[valueType.objectTypeName]?.role === "input") {
        return "Input type reference";
      }
      if (schema.objects[valueType.objectTypeName]?.role === "event") {
        return "Event type reference";
      }
      return "Object type reference";
    case "UnionValueType":
      return "Union type reference";
    case "ListValueType":
      return `List of ${valueTypeToTypeDescription(
        schema,
        valueType.itemValueType
      ).toLowerCase()}s`;
    case "VoidValueType":
      return "Event";

    case "FunctionValueType":
      return valueTypeToTypeDescription(schema, valueType.returnValueType);
    default:
      throw new Error(`unexpected value type: ${valueType.type}`);
  }
}

function unwrapValueType(valueType: ValueType): ValueType {
  const valueTypes = unrollValueType(valueType);

  return valueTypes[valueTypes.length - 1];
}
