import React, { useEffect, useMemo, useState } from "react";
import { rootFieldName } from "@hypertune/shared-internal";
import {
  useActiveCommitQuery,
  useCommitLazyQuery,
  useProjectBranchQuery,
  useProjectQuery,
} from "../../generated/graphql";
import trackAnalyticsEvent from "../../lib/trackAnalyticsEvent";
import ProjectTopBar from "./controls/ProjectTopBar";
import ProjectSidebar from "./controls/ProjectSidebar";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  FullDraftCommitDerivedData,
  resetLogicEditorState,
  setActiveCommit,
  setDraftCommit,
  setDraftCommitExpression,
  setLogicEditorExpressionEditorSelectedItem,
  setObjectAddFieldModalState,
} from "./projectSlice";
import CommandPalette from "./controls/CommandPalette";
import {
  ProjectView,
  ProjectSelectedStateUpdate,
  useProjectSelectedState,
} from "./projectHooks";
import Project from "./Project";
import {
  draftCommitId,
  greyBgHoverHex,
  greyHex,
  queryPollIntervalLongMs,
} from "../../lib/constants";
import getCommitData from "../../lib/query/getCommitData";
import ModalWithContent from "../../components/ModalWithContent";
import useShowSetupModal from "./setup/useShowSetupModal";
import LoadingOrErrorView from "./LoadingOrErrorView";
import ObjectAddFieldModal from "./schema/typeEditor/object/ObjectAddFieldModal";
import NewTypeModal from "./schema/typeEditor/NewTypeModal";
import NewSplitModal from "./split/splitEditor/NewSplitModal";
import NewViewModal from "./analytics/NewViewModal";
import ProjectSetupModal from "./setup/ProjectSetupModal";
import {
  canContributeToProject,
  canEditProject,
} from "../../lib/query/rolePermissions";
import { useHypertune } from "../../generated/hypertune.react";
import toTree from "./logic/expression/toTree";
import getDefaultFieldPathAndTopLevelEnumTypeName from "../../lib/expression/getDefaultFieldPathAndTopLevelEnumTypeName";
import { DiffCommitData } from "../../lib/types";
import { useLogicSelectedFieldPath } from "./logicHooks";
import { resetAnalyticsEditor } from "./analytics/analyticsSlice";
import NewVariableModal from "./logic/NewVariableModal";

export default function ProjectPage(): React.ReactElement | null {
  const { selected, setSelected } = useProjectSelectedState();

  if (!selected.projectId || !selected.branchName || !selected.commitId) {
    return null;
  }

  return (
    <ProjectPageInner
      selectedProjectId={selected.projectId}
      selectedBranchName={selected.branchName}
      selectedCommitId={selected.commitId}
      selectedView={selected.view}
      setSelected={setSelected}
    />
  );
}

function ProjectPageInner({
  selectedProjectId,
  selectedBranchName,
  selectedCommitId,
  selectedView,
  setSelected,
}: {
  selectedProjectId: string;
  selectedBranchName: string;
  selectedCommitId: string;
  selectedView: ProjectView;
  setSelected: (newSelected: ProjectSelectedStateUpdate) => void;
}): React.ReactElement | null {
  const [isCommandPaletteVisible, setIsCommandPaletteVisible] = useState(false);

  const content = useHypertune().content();
  const dispatch = useAppDispatch();
  const activeCommitId = useAppSelector(
    (state) => state.project.activeCommitId
  );
  const hasChanges = useAppSelector(
    (state) => state.project.draftCommitDerived.hasChanges
  );
  const isSaving = useAppSelector((state) => state.project.isSaving);
  const [setupActive] = useShowSetupModal();
  const [resetting, setResetting] = useState(false);
  const {
    error: branchError,
    loading: branchLoading,
    data: branchData,
    refetch: refetchBranch,
  } = useProjectBranchQuery({
    variables: { projectId: selectedProjectId, branchName: selectedBranchName },
  });
  const {
    error: projectError,
    loading: projectLoading,
    data: projectData,
  } = useProjectQuery({
    variables: { projectId: selectedProjectId },
  });
  const {
    data: activeCommitData,
    startPolling,
    stopPolling,
    refetch: refetchActiveCommitData,
  } = useActiveCommitQuery({
    variables: { projectId: selectedProjectId, branchName: selectedBranchName },
    fetchPolicy: "no-cache",
  });
  const [getCommit, commitQuery] = useCommitLazyQuery();
  useEffect(() => {
    if (!isSaving && selectedCommitId !== draftCommitId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getCommit({ variables: { commitId: selectedCommitId } });
    }
  }, [getCommit, selectedCommitId, isSaving]);

  const [showHasNewCommitModal, setShowHasNewCommitModal] = useState(false);

  useEffect(() => {
    document.title = "Hypertune";
    trackAnalyticsEvent("project_page_view");
  }, []);

  useEffect(() => {
    startPolling(queryPollIntervalLongMs);

    return () => {
      // Cleanup state when navigating away.
      stopPolling();
      dispatch(resetLogicEditorState());
      dispatch(setActiveCommit(undefined));
      dispatch(resetAnalyticsEditor());
    };
  }, [dispatch, startPolling, stopPolling]);

  useEffect(() => {
    if (
      isSaving ||
      !activeCommitData?.projectBranch.activeCommit ||
      activeCommitId === activeCommitData.projectBranch.activeCommit.id
    ) {
      return;
    }
    if (hasChanges && !setupActive) {
      // If there are changes, then we just show the warning modal.
      // We check whether onboarding is active as a failsafe, so that we don't interrupt it.
      setShowHasNewCommitModal(true);
      return;
    }
    // Otherwise quietly update the draft commit.
    dispatch(setActiveCommit(activeCommitData.projectBranch.activeCommit));
    dispatch(
      setDraftCommit(getCommitData(activeCommitData.projectBranch.activeCommit))
    );
    refetchBranch().catch((refetchError) => {
      console.error("Error refetching project:", refetchError);
    });

    // We don't want to trigger this hook when activeCommitId, hasChanges or isSaving changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refetchBranch, activeCommitData]);

  useEffect(() => {
    dispatch(setActiveCommit(branchData?.projectBranch.activeCommit));
  }, [dispatch, branchData]);

  const canEdit = canEditProject(projectData?.primaryBusiness?.role);
  const canContribute = canContributeToProject(
    projectData?.primaryBusiness?.role
  );
  const isViewingOldCommit = selectedCommitId !== draftCommitId;
  const readOnly = isViewingOldCommit || !canContribute;

  const selectedFieldPath = useLogicSelectedFieldPath();
  const draftCommit = useAppSelector((state) => state.project.draftCommit);
  const draftCommitDerivedData = useAppSelector(
    (state) => state.project.draftCommitDerived
  );
  const commitId = isViewingOldCommit ? selectedCommitId : null;
  const commit = isViewingOldCommit
    ? commitQuery.data
      ? getCommitData(commitQuery.data.commit)
      : undefined
    : draftCommit;
  const commitDerivedData: FullDraftCommitDerivedData = isViewingOldCommit
    ? commit
      ? {
          ...getDefaultFieldPathAndTopLevelEnumTypeName(
            commit.schema,
            commit.expression,
            selectedFieldPath ?? undefined
          ),
          hasChanges: true,
        }
      : {
          hasChanges: false,
          topLevelEnum: null,
          defaultFieldPath: [rootFieldName],
        }
    : {
        ...draftCommitDerivedData,
        ...(draftCommit
          ? getDefaultFieldPathAndTopLevelEnumTypeName(
              draftCommit.schema,
              draftCommit.expression,
              selectedFieldPath ?? undefined
            )
          : { topLevelEnum: null, defaultFieldPath: [rootFieldName] }),
      };

  const defaultBaseCommit = {
    schema: {
      objects: {},
      enums: {},
      unions: {},
    },
    expression: null,
    splits: {},
  };
  const baseCommit: DiffCommitData = isViewingOldCommit
    ? commitQuery.data?.commit.parent
      ? getCommitData(commitQuery.data.commit.parent)
      : defaultBaseCommit
    : branchData?.projectBranch.activeCommit
      ? getCommitData(branchData?.projectBranch.activeCommit)
      : defaultBaseCommit;

  const expressionTree = useMemo(() => {
    if (commit?.schema && commit?.splits && commit?.expression) {
      return (
        toTree({
          schema: commit.schema,
          splits: commit.splits,
          expression: commit.expression,
          setExpression: (newExpression) => {
            if (newExpression) {
              dispatch(setDraftCommitExpression(newExpression));
            }
          },
          setAddObjectFieldModalState: (newState) =>
            dispatch(setObjectAddFieldModalState(newState)),
          setExpressionEditorSelectedItem: (newSelectedItem) =>
            dispatch(
              setLogicEditorExpressionEditorSelectedItem(newSelectedItem)
            ),
        }) ?? undefined
      );
    }
    return undefined;
  }, [dispatch, commit?.schema, commit?.splits, commit?.expression]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <ProjectTopBar
          meId={projectData?.me.id}
          canEdit={canEdit}
          canContribute={canContribute}
          project={projectData?.project}
          branch={branchData?.projectBranch}
          selectedView={selectedView}
          selectedProjectId={selectedProjectId}
          selectedBranchName={selectedBranchName}
          setSelectedBranch={(newBranchName) =>
            setSelected({ branchName: newBranchName, commitId: draftCommitId })
          }
          setSelectedView={(newView) => setSelected({ view: newView })}
          setSelectedCommitId={(newCommitId) =>
            setSelected({ commitId: newCommitId })
          }
          commitId={commitId}
          commitMessage={
            isViewingOldCommit && commitQuery.data
              ? commitQuery.data.commit.message
              : undefined
          }
          commit={commit}
          expressionTree={expressionTree}
          commitDerivedData={commitDerivedData}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <ProjectSidebar
            projectId={projectData?.project.id}
            showSetup={canEdit}
            commitDerivedData={commitDerivedData}
            selectedView={selectedView}
            setSelectedView={(newView) => setSelected({ view: newView })}
            setIsCommandPaletteVisible={setIsCommandPaletteVisible}
          />
          <div className="flex h-full w-full flex-grow flex-col overflow-hidden">
            {(branchError ||
              branchLoading ||
              projectError ||
              projectLoading ||
              (commitQuery.called &&
                (commitQuery.loading || commitQuery.error))) && (
              <LoadingOrErrorView
                selectedView={selectedView}
                error={branchError || projectError || commitQuery.error}
                projectId={selectedProjectId}
              />
            )}
            {branchData &&
              selectedCommitId !== draftCommitId &&
              selectedView !== "pull-requests" &&
              selectedView !== "settings" &&
              selectedView !== "analytics" && (
                <p
                  style={{
                    borderBottom: `1px solid ${greyHex}`,
                    padding: 10,
                    margin: 0,
                    background: greyBgHoverHex,
                  }}
                >
                  Viewing{" "}
                  {selectedCommitId === branchData.projectBranch.activeCommit.id
                    ? "the latest"
                    : "an old"}{" "}
                  commit in read-only mode.
                </p>
              )}
            {projectData && branchData && commit && commitDerivedData && (
              <Project
                selectedView={selectedView}
                meId={projectData.me.id}
                canEdit={canEdit}
                canContribute={canContribute}
                project={projectData.project}
                branch={branchData.projectBranch}
                commitId={commitId}
                commit={commit}
                commitDerivedData={commitDerivedData}
                expressionTree={expressionTree}
                readOnly={readOnly}
                draftHasChanges={draftCommitDerivedData.hasChanges}
                baseCommit={baseCommit}
                meta={
                  isViewingOldCommit
                    ? commitQuery.data
                      ? commitQuery.data.commit
                      : undefined
                    : { author: projectData.me }
                }
              />
            )}
            <CommandPalette
              project={projectData?.project}
              defaultFieldPath={commitDerivedData.defaultFieldPath}
              expressionTree={expressionTree ?? null}
              isVisible={isCommandPaletteVisible}
              setIsVisible={setIsCommandPaletteVisible}
              setSelectedView={(newView) => setSelected({ view: newView })}
            />
          </div>
        </div>
      </div>
      {projectData && (
        <ProjectSetupModal
          project={projectData.project}
          branch={branchData?.projectBranch}
        />
      )}
      <NewVariableModal />
      <ObjectAddFieldModal />
      <NewSplitModal />
      <NewTypeModal />
      <NewViewModal />
      {showHasNewCommitModal && (
        <ModalWithContent
          disableAwayClickClose
          content={content.logic().newCommitModal().get()}
          onClose={() => {
            setShowHasNewCommitModal(false);
          }}
          saveLoading={resetting}
          onSave={async () => {
            setResetting(true);
            try {
              await refetchActiveCommitData();

              if (!activeCommitData) {
                console.error("no activeCommitData after refetch");
                return;
              }

              dispatch(
                setActiveCommit(activeCommitData.projectBranch.activeCommit)
              );
              dispatch(
                setDraftCommit(
                  getCommitData(activeCommitData.projectBranch.activeCommit)
                )
              );
              await refetchBranch();
              setShowHasNewCommitModal(false);
            } finally {
              setResetting(false);
            }
          }}
        />
      )}
    </>
  );
}
