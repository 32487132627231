import { useState } from "react";
import {
  Expression,
  ValueType,
  fieldPathSeparator,
  mapExpression,
  uniqueId,
} from "@hypertune/sdk/src/shared";
import {
  checkSchemaName,
  formatFieldSchemaName,
  queryObjectTypeName,
  rootObjectTypeName,
  variablePathSuffix,
} from "@hypertune/shared-internal";
import getDefaultExpression from "@hypertune/shared-internal/src/expression/getDefaultExpression";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import Modal from "../../../components/Modal";
import Label from "../../../components/Label";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import TextInput from "../../../components/input/TextInput";
import {
  DraftCommit,
  NewVariableModalState,
  setDraftCommitExpression,
  setNewVariableModal,
} from "../projectSlice";
import SchemaNameError from "../schema/typeEditor/SchemaNameError";
import ValueTypeSelector, {
  combineValueTypes,
  enumValueTypeOptionGroupFromSchema,
  getObjectValueTypeOptionGroup,
  primitiveValueTypeOptionGroup,
} from "../schema/typeEditor/object/ValueTypeSelector";
import { useLogicSetSelectedFieldPath } from "../logicHooks";
import Variable from "../../../components/icons/Variable";

export const width = 395;

export default function NewVariableModal(): React.ReactElement | null {
  const draftCommit = useAppSelector(
    (globalState) => globalState.project.draftCommit
  );
  const state = useAppSelector(
    (globalState) => globalState.project.newVariableModal
  );

  if (!state || !draftCommit) {
    return null;
  }
  return <NewVariableModalInner draftCommit={draftCommit} {...state} />;
}

function NewVariableModalInner({
  draftCommit,
  defaultFieldPath,
}: {
  draftCommit: DraftCommit;
} & NewVariableModalState): React.ReactElement | null {
  const { schema, expression } = draftCommit;

  const dispatch = useAppDispatch();
  const setSelectedFieldPath = useLogicSetSelectedFieldPath();

  const variableNames = new Set(collectVariableNames(expression));

  const optionGroups = [
    primitiveValueTypeOptionGroup(/* includeVoid */ false),
    enumValueTypeOptionGroupFromSchema(schema, /* includeNewOptions */ false),
    getObjectValueTypeOptionGroup(
      Object.entries(schema.objects).filter(
        ([, objectSchema]) => objectSchema.role === "input"
      ),
      ["input"],
      "input",
      /* includeNewOptions */ false
    ),
    getObjectValueTypeOptionGroup(
      Object.entries(schema.objects).filter(
        ([objectTypeName, objectSchema]) =>
          objectSchema.role === "output" &&
          objectTypeName !== queryObjectTypeName &&
          objectTypeName !== rootObjectTypeName
      ),
      ["output"],
      "object",
      /* includeNewOptions */ false
    ),
  ];

  const [valueTypes, setValueTypes] = useState<ValueType[]>([
    optionGroups[0].options[0].value,
  ]);

  const [variableName, setVariableName] = useState("");
  const variableSchemaName = formatFieldSchemaName(variableName);
  const nameError = checkSchemaName(variableSchemaName); // TODO

  const isValid =
    variableName !== "" &&
    nameError.valid &&
    !variableNames.has(variableSchemaName) &&
    !valueTypes.some((value) => value === null);

  function onClose(): void {
    dispatch(setNewVariableModal(undefined));
  }

  function onSubmit(): void {
    if (!isValid) {
      return;
    }
    const valueType = combineValueTypes(valueTypes);
    dispatch(
      setDraftCommitExpression(
        mapExpression((expr) => {
          if (
            expr &&
            expr.type === "ObjectExpression" &&
            expr.valueType.type === "ObjectValueType" &&
            expr.valueType.objectTypeName === rootObjectTypeName
          ) {
            return {
              id: uniqueId(),
              type: "ApplicationExpression",
              valueType: {
                type: "ObjectValueType",
                objectTypeName: rootObjectTypeName,
              },
              function: {
                id: uniqueId(),
                type: "FunctionExpression",
                valueType: {
                  type: "FunctionValueType",
                  parameterValueTypes: [valueType],
                  returnValueType: {
                    type: "ObjectValueType",
                    objectTypeName: rootObjectTypeName,
                  },
                },
                parameters: [{ id: uniqueId(), name: variableSchemaName }],
                body: expr,
              },
              arguments: [
                getDefaultExpression(
                  schema,
                  /* variables */ {},
                  getConstraintFromValueType(valueType),
                  /* seenObjectTypeNames */ new Set()
                ),
              ],
            };
          }
          return expr;
        }, expression) as Expression
      )
    );
    setSelectedFieldPath(
      `${defaultFieldPath.join(fieldPathSeparator)}${fieldPathSeparator}${variableSchemaName}${variablePathSuffix}`
    );
    onClose();
  }

  return (
    <Modal
      buttonLayout="end"
      modalStyle="medium"
      onClose={onClose}
      closeOnEsc
      closeText="Cancel"
      title={
        <div className="flex flex-row items-center gap-2">
          <Variable />
          <Label type="title3" className="text-tx-default">
            Add new variable
          </Label>
        </div>
      }
      childrenStyle={{ paddingLeft: 0, paddingRight: 0 }}
      saveText="Create"
      saveIntent="neutral"
      saveWeight="outlined"
      saveDisabled={!isValid}
      onSave={onSubmit}
    >
      <div className="mt-4 flex flex-col text-tx-default">
        <div className="flex max-h-[510px] flex-col overflow-y-auto px-3">
          <Label type="title4" className="mb-[9px] text-tx-muted">
            Name
          </Label>
          <TextInput
            placeholder="Enter a name for this variable"
            value={variableName}
            onChange={setVariableName}
            focusOnMount
            trimOnBlur={false}
            readOnly={false}
            onEnter={onSubmit}
            size="medium"
            error={
              variableName && !nameError.valid ? (
                <SchemaNameError schemaCheckOrError={nameError} />
              ) : variableNames.has(variableSchemaName) ? (
                "A variable with this name already exists"
              ) : null
            }
          />
          {variableSchemaName && (
            <TextInput
              value={variableSchemaName}
              trimOnBlur={false}
              readOnly
              onChange={() => {
                // Dummy
              }}
              style={{ marginTop: 10 }}
            />
          )}
          <ValueTypeSelector
            optionGroups={optionGroups}
            valueTypes={valueTypes}
            setValueTypes={setValueTypes}
            dropdownStyle={{
              minWidth: width,
              caret: "down",
              scrollToPosition: "center",
              showButtonSubtitle: true,
              buttonClassName:
                "border min-h-[46px] max-w-[395px] px-[14px] py-[12px]",
              subtitleClassName: "max-w-[320px]",
              panelClassName: "max-w-[395px] overflow-x-hidden",
            }}
            newTypeName={variableName}
            onOpenNewTypeModal={onClose}
          />
        </div>
      </div>
    </Modal>
  );
}

function collectVariableNames(expression: Expression | null): string[] {
  if (!expression || isRootObjectExpression(expression)) {
    return [];
  }
  switch (expression.type) {
    case "ApplicationExpression":
      return collectVariableNames(expression.function);
    case "FunctionExpression":
      return expression.parameters.map(({ name }) => name);
    case "ObjectExpression":
      return Object.values(expression.fields).flatMap((field) =>
        collectVariableNames(field)
      );
    case "EnumSwitchExpression":
      return Object.values(expression.cases)
        .flatMap((field) => collectVariableNames(field))
        .concat(collectVariableNames(expression.control));
    case "SwitchExpression":
      return Object.values(expression.cases)
        .flatMap(({ when, then }) =>
          collectVariableNames(when).concat(collectVariableNames(then))
        )
        .concat(collectVariableNames(expression.control))
        .concat(collectVariableNames(expression.default));
    default:
      return [];
  }
}

function isRootObjectExpression(expression: Expression): boolean {
  return (
    expression &&
    expression.type === "ObjectExpression" &&
    expression.valueType.type === "ObjectValueType" &&
    expression.valueType.objectTypeName === rootObjectTypeName
  );
}
