import { Schema } from "@hypertune/sdk/src/shared";
import {
  enumToCode,
  objectToCode,
  unionToCode,
  tagsToCode,
} from "@hypertune/shared-internal/src/schema/getSchemaCodeFromSchema";
import { diff } from "deep-object-diff";
import { diffLines } from "diff";
import { HunkData } from "react-diff-view";
import { newLineRegex } from "../../../lib/constants";
import { StrictDeepPartial } from "../../../lib/types";

const typeGroupToCodeFunction = {
  objects: objectToCode,
  unions: unionToCode,
  enums: enumToCode,
};

const typeGroups: (keyof Omit<Schema, "tags">)[] = [
  "objects",
  "unions",
  "enums",
];

export type TypeGroup = "object" | "input" | "enum" | "event" | "tag";

export type SchemaDiffValue = {
  typeName: string;
  typeGroup: TypeGroup;
  hunks: HunkData[];
};

export default function getSchemaDiffValues(
  currentSchema: Schema,
  newSchema: Schema
): SchemaDiffValue[] {
  const schemaDiff = diff(
    schemaWithIndexes(currentSchema),
    schemaWithIndexes(newSchema)
  ) as StrictDeepPartial<Schema>;
  const diffValues = new Array<SchemaDiffValue>();
  console.debug("Schema diff value", schemaDiff);

  typeGroups.forEach((typeGroup) => {
    if (typeGroup in schemaDiff && schemaDiff[typeGroup]) {
      Object.keys(schemaDiff[typeGroup] as object).forEach((typeName) => {
        const codeFunc = typeGroupToCodeFunction[typeGroup];

        if (
          typeGroup === "objects" &&
          (currentSchema[typeGroup][typeName]?.role === "args" ||
            newSchema[typeGroup][typeName]?.role === "args")
        ) {
          return;
        }
        const hunks = getCodeDiffHunks(
          currentSchema?.[typeGroup]?.[typeName]
            ? codeFunc(currentSchema, typeName)
            : "",
          newSchema?.[typeGroup]?.[typeName]
            ? codeFunc(newSchema, typeName)
            : ""
        );
        if (
          !hunks.some((hunk) =>
            hunk.changes.some((change) => change.type !== "normal")
          )
        ) {
          return;
        }

        diffValues.push({
          typeName,
          typeGroup:
            typeGroup === "enums"
              ? "enum"
              : typeGroup === "objects"
                ? newSchema[typeGroup][typeName]
                  ? newSchema[typeGroup][typeName].role === "output"
                    ? "object"
                    : (newSchema[typeGroup][typeName].role as TypeGroup)
                  : currentSchema[typeGroup][typeName]
                    ? currentSchema[typeGroup][typeName].role === "output"
                      ? "object"
                      : (currentSchema[typeGroup][typeName].role as TypeGroup)
                    : "object" // Unreachable fallback
                : "object", // Unions of objects.
          hunks,
        });
      });
    }
  });

  if ("tags" in schemaDiff) {
    diffValues.push({
      typeName: "tags",
      typeGroup: "tag",
      hunks: getCodeDiffHunks(tagsToCode(currentSchema), tagsToCode(newSchema)),
    });
  }

  return diffValues;
}

function getCodeDiffHunks(currentCode: string, newCode: string): HunkData[] {
  const currentCodeLines = currentCode.split(newLineRegex);
  const newCodeLines = newCode.split(newLineRegex);

  const rawChanges = diffLines(currentCode, newCode);
  let currentLine = 0;
  let newLine = 0;

  const changes: HunkData["changes"] = rawChanges.flatMap((change) => {
    const result: HunkData["changes"] = [];
    const changeLines = change.value.trimEnd().split(newLineRegex);

    if (change.added) {
      changeLines.forEach((changeLine) => {
        result.push({
          type: "insert",
          content: changeLine,
          lineNumber: newLine,
          isInsert: true,
        });
        newLine += 1;
      });
    } else if (change.removed) {
      changeLines.forEach((changeLine) => {
        result.push({
          type: "delete",
          content: changeLine,
          lineNumber: currentLine,
          isDelete: true,
        });
        currentLine += 1;
      });
    } else {
      changeLines.forEach((changeLine) => {
        result.push({
          type: "normal",
          content: changeLine,
          oldLineNumber: currentLine,
          newLineNumber: newLine,
          isNormal: true,
        });
        currentLine += 1;
        newLine += 1;
      });
    }
    return result;
  });

  return [
    {
      content: currentCode,
      oldStart: 0,
      newStart: 0,
      oldLines: currentCodeLines.length,
      newLines: newCodeLines.length,
      changes,
    },
  ];
}

function schemaWithIndexes(schema: Schema): object {
  return {
    enums: nestedObjectFieldsWithIndexes(schema.enums, "values"),
    objects: nestedObjectFieldsWithIndexes(schema.objects, "fields"),
    unions: nestedObjectFieldsWithIndexes(schema.unions, "variants"),
    tags: schema.tags,
  };
}

function nestedObjectFieldsWithIndexes<
  U extends { [key2: string]: any },
  T extends { [key: string]: U },
>(object: T, key: keyof U): object {
  return Object.fromEntries(
    Object.entries(object).map(([nestedKey, value]) => [
      nestedKey,
      {
        ...value,
        [key]: objectFieldsWithIndexes(value[key]),
      },
    ])
  );
}

function objectFieldsWithIndexes(object: { [key: string]: any }): {
  [key: string]: any;
} {
  return Object.fromEntries(
    Object.entries(object).map(([key, value], index) => [
      key,
      value[key] !== null && typeof value[key] === "object"
        ? {
            ...value,
            [key]: objectFieldsWithIndexes(value[key]),
          }
        : {
            value,
            _index: index,
          },
    ])
  );
}
