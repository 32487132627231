import Button from "../../../components/buttons/Button";
import { useUpdateAnalyticsViewMutation } from "../../../generated/graphql";
import { useAppSelector } from "../../../app/hooks";
import { useSelectedAnalyticsViewId } from "./analyticsHooks";
import { refetchAnalyticsViewsQueries } from "../../../lib/query/refetchQueries";

export default function SaveButton({
  disabled,
}: {
  disabled: boolean;
}): React.ReactElement | null {
  const hasChanges = useAppSelector(
    (state) => state.projectAnalytics.hasChanges
  );
  const steps = useAppSelector((state) => state.projectAnalytics.steps);
  const [selectedViewId] = useSelectedAnalyticsViewId();

  const [updateAnalyticsView, { loading }] = useUpdateAnalyticsViewMutation({
    refetchQueries: refetchAnalyticsViewsQueries,
    awaitRefetchQueries: true,
  });

  async function updateView(): Promise<void> {
    if (selectedViewId === null || !hasChanges) {
      return;
    }
    try {
      await updateAnalyticsView({
        variables: {
          input: {
            id: selectedViewId,
            funnelStepsJson: JSON.stringify(steps),
          },
        },
      });
    } catch (error) {
      console.error("updateAnalyticsView error:", error);
    }
  }

  return (
    <Button
      intent="primary"
      size="large"
      weight="elevated"
      text="Save"
      disabled={disabled || !hasChanges}
      loading={loading}
      onClick={updateView}
    />
  );
}
