import { ArrowRight } from "@phosphor-icons/react";
import { lightGreyHex } from "../../lib/constants";

export default function GoToArrowButton({
  onClick,
  isVariable,
}: {
  onClick: () => void;
  isVariable?: boolean;
}): React.ReactElement | null {
  return (
    <div
      className="group absolute right-0 top-0 cursor-pointer pr-2 pt-[7px]"
      onClick={onClick}
    >
      <div className="-m-2 p-2">
        <div
          className={`${isVariable ? "group-hover:filter-base-pink" : "group-hover:filter-intent-primary"} -mx-[5px] -my-[4px] rounded-lg px-[5px] py-[4px] ${isVariable ? "group-hover:bg-base-pink/5" : "group-hover:bg-intent-primary/5"}`}
        >
          <ArrowRight weight="bold" color={lightGreyHex} />
        </div>
      </div>
    </div>
  );
}
