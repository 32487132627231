/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { GitPullRequest } from "@phosphor-icons/react";
import { DateTime } from "luxon";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Label from "../../../components/Label";
import SchemaDiff from "./SchemaDiff";
import ExpressionDiff from "./ExpressionDiff";
import { CommitMetadata, DiffCommitData } from "../../../lib/types";
import SplitsDiff from "./SplitsDiff";
import DiffContainer from "./DiffContainer";
import SidebarContainer from "../SidebarContainer";
import SidebarItem, { SidebarItemProps } from "../../../components/SidebarItem";
import { formatJsonTime } from "../../../lib/generic/formatDate";
import useGetCommitDiff, { DiffNode } from "./useGetCommitDiff";
import TypeIcon from "../../../components/icons/TypeIcon";
import ValueTypeConstraintIcon from "../logic/expression/ValueTypeConstraintIcon";
import useStickyState from "../../../lib/hooks/useStickyState";
import Button from "../../../components/buttons/Button";
import EmptyStateContainer from "../../../components/EmptyStateContainer";
import UserWithTime from "../../../components/UserWithTime";
import RightSidebar from "../../../components/icons/RightSidebar";
import twMerge from "../../../lib/twMerge";
import { useHypertune } from "../../../generated/hypertune.react";

const leftSidebarCollapsedKey = "diff-view-sidebar-collapsed";
const rightSidebarCollapsedKey = "diff-view-right-sidebar-collapsed";

const pageContainerClassName =
  "h-full w-full flex-col bg-bg-light bg-dotted overflow-hidden";
const contentContainerClassName =
  "flex h-full w-full flex-grow flex-row items-stretch overflow-hidden";
const contentClassName =
  "flex h-full w-full flex-col gap-4 overflow-auto px-4 pt-4";

export type DiffEditorProps = {
  meId: string;
  isVisible: boolean;
  isCurrent?: boolean;
  currentCommit: DiffCommitData;
  newCommit: DiffCommitData;
  newCommitHasChanges: boolean;
  newCommitHasError?: boolean;
  meta?: CommitMetadata;
};

export type Author = {
  id: string;
  displayName: string;
  email: string;
  imageUrl: string;
};

type SelectedDiff = {
  type: "logic" | "splits" | "schema";
  index: number;
};

export default function DiffEditor(
  props: DiffEditorProps
): React.ReactElement | null {
  const { isVisible } = props;

  if (!isVisible) {
    // Don't render the editor unless it's visible to avoid
    // unnecessary expensive diff calculation.
    return null;
  }

  return <DiffEditorContent {...props} />;
}

export function DiffEditorContent({
  meId,
  isVisible,
  isCurrent,
  currentCommit,
  newCommit,
  newCommitHasChanges,
  newCommitHasError,
  meta,
  customTitle,
  timeFormat = "absolute",
  topBarAction,
  rightSidebar,
}: DiffEditorProps & {
  customTitle?: React.ReactNode;
  timeFormat?: "absolute" | "relative";
  topBarAction?: React.ReactNode;
  rightSidebar?: React.ReactNode;
}): React.ReactElement | null {
  const content = useHypertune().content().diff();

  const [searchText, setSearchText] = useState("");
  const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useStickyState(
    false,
    leftSidebarCollapsedKey
  );
  const [rightSidebarCollapsed, setRightSidebarCollapsed] = useStickyState(
    false,
    rightSidebarCollapsedKey
  );
  const [selectedDiff, setSelectedDiff] = useState<SelectedDiff | null>(null);

  const {
    currentContext,
    newContext,
    currentExpressionIntentMap,
    newExpressionIntentMap,
    expressionDiffPathTree,
    expressionDiffNodes,
    splitsDiffsMeta,
    newSplitsIntentMap,
    schemaDiffValues,
    hasExpressionDiff,
    hasSplitsDiff,
    hasSchemaDiff,
    hasDiff,
  } = useGetCommitDiff({
    meId,
    currentCommit,
    newCommit,
  });

  return (
    <div
      className={`${!isVisible ? "hidden" : "flex"} ${pageContainerClassName}`}
    >
      <TopBar
        isCurrent={isCurrent}
        meta={meta}
        timeFormat={timeFormat}
        customTitle={customTitle}
        toggleLeftSidebar={() => setLeftSidebarCollapsed(!leftSidebarCollapsed)}
        toggleRightSidebar={
          rightSidebar
            ? () => setRightSidebarCollapsed(!rightSidebarCollapsed)
            : undefined
        }
        action={topBarAction}
      />

      <div className={contentContainerClassName}>
        {!leftSidebarCollapsed && (
          <SidebarContainer
            searchText={searchText}
            setSearchText={setSearchText}
            childrenClassName={!hasDiff ? "pb-[5px]" : ""}
          >
            {!hasDiff && (
              <EmptyStateContainer
                icon={<GitPullRequest />}
                content={content.emptyState().get()}
              />
            )}
            {hasExpressionDiff && (
              <ExpressionSideBarItem
                node={expressionDiffPathTree}
                selectedDiff={selectedDiff}
                setSelectedDiff={setSelectedDiff}
              />
            )}
            {hasSplitsDiff && (
              <SidebarItem
                icon={<TypeIcon type="split" size="small" />}
                className="px-3 py-[9px]"
                title="Splits"
                collapseOnClickWhenNotSelected
              >
                {splitsDiffsMeta.map(
                  ({ splitId, splitType, shortLabel }, index) => (
                    <SidebarItem
                      key={splitId}
                      icon={<TypeIcon type={splitType} size="small" />}
                      title={shortLabel}
                      className="px-3 py-[11px]"
                      isSelected={
                        selectedDiff?.type === "splits" &&
                        selectedDiff?.index === index
                      }
                      onClick={() => setSelectedDiff({ type: "splits", index })}
                    />
                  )
                )}
              </SidebarItem>
            )}
            {hasSchemaDiff && (
              <SidebarItem
                icon={<TypeIcon type="object" size="small" />}
                className="px-3 py-[9px]"
                title="Schema"
                collapseOnClickWhenNotSelected
              >
                {schemaDiffValues.map(({ typeGroup, typeName }, index) => (
                  <SidebarItem
                    icon={<TypeIcon type={typeGroup} size="small" />}
                    title={toStartCase(typeName)}
                    className="px-3 py-[11px]"
                    isSelected={
                      selectedDiff?.type === "schema" &&
                      selectedDiff?.index === index
                    }
                    onClick={() => setSelectedDiff({ type: "schema", index })}
                  />
                ))}
              </SidebarItem>
            )}
          </SidebarContainer>
        )}

        <div className={contentClassName} style={{ paddingBottom: "75vh" }}>
          {(!newCommitHasChanges || !hasDiff) && (
            <Message text="No changes detected." />
          )}
          {newCommitHasError && (
            <Message text="Draft changes have errors, please fix them first." />
          )}
          {newCommitHasChanges && !newCommitHasError && currentCommit && (
            <>
              <ExpressionDiff
                nodes={expressionDiffNodes}
                currentContext={currentContext}
                newContext={newContext}
                currentExpressionIntentMap={currentExpressionIntentMap}
                newExpressionIntentMap={newExpressionIntentMap}
                selectedDiffIndex={
                  !leftSidebarCollapsed && selectedDiff?.type === "logic"
                    ? selectedDiff.index
                    : null
                }
              />
              <SplitsDiff
                currentSplits={currentCommit.splits}
                newSplits={newCommit.splits}
                splitsDiffsMeta={splitsDiffsMeta}
                newSplitsIntentMap={newSplitsIntentMap}
                selectedDiffIndex={
                  !leftSidebarCollapsed && selectedDiff?.type === "splits"
                    ? selectedDiff.index
                    : null
                }
                currentContext={currentContext}
              />
              {hasSchemaDiff && (
                <SchemaDiff
                  schemaDiffValues={schemaDiffValues}
                  selectedDiffIndex={
                    !leftSidebarCollapsed && selectedDiff?.type === "schema"
                      ? selectedDiff.index
                      : null
                  }
                />
              )}
            </>
          )}
        </div>
        {!rightSidebarCollapsed && rightSidebar}
      </div>
    </div>
  );
}

function TopBar({
  isCurrent,
  meta,
  timeFormat,
  customTitle,
  action,
  toggleLeftSidebar,
  toggleRightSidebar,
}: {
  isCurrent?: boolean;
  meta?: CommitMetadata;
  timeFormat: "absolute" | "relative";
  customTitle?: React.ReactNode;
  action?: React.ReactNode;
  toggleLeftSidebar?: () => void;
  toggleRightSidebar?: () => void;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "flex w-full flex-row border-b bg-white px-6 py-4",
        !isCurrent ? "min-h-[83.5px]" : null
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          <Label type="title1">
            {customTitle || (
              <>
                {isCurrent ? "Draft " : "Commit "}
                {meta?.message || ""} diff
              </>
            )}
          </Label>
        </div>
        <div className="flex flex-row items-center gap-3">
          {meta && (
            <UserWithTime
              muted
              user={meta.author}
              time={
                !meta.createdAt
                  ? undefined
                  : timeFormat === "absolute"
                    ? formatJsonTime(meta.createdAt)
                    : `Created ${DateTime.fromISO(meta.createdAt).toRelative()}`
              }
            />
          )}
        </div>
      </div>
      <div className="ml-auto flex flex-col items-end gap-2">
        {action}
        <div className="mt-auto flex flex-row items-center gap-3">
          {toggleLeftSidebar && (
            <Button
              weight="minimal"
              icon={<RightSidebar className="rotate-180" />}
              onClick={toggleLeftSidebar}
            />
          )}
          {toggleRightSidebar && (
            <Button
              icon={<RightSidebar />}
              weight="minimal"
              onClick={toggleRightSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function ExpressionSideBarItem({
  node,
  selectedDiff,
  setSelectedDiff,
}: {
  node: DiffNode;
  selectedDiff: SelectedDiff | null;
  setSelectedDiff: (newSelectedDiff: SelectedDiff) => void;
}): React.ReactElement<SidebarItemProps> | null {
  const hasChildren = node.children.length > 0;
  const { iconProps } = node;
  return (
    <SidebarItem
      icon={<ValueTypeConstraintIcon {...iconProps} />}
      className={`px-3 ${hasChildren ? "py-[9px]" : "py-[11px]"}`}
      title={node.label}
      isSelected={
        !hasChildren &&
        selectedDiff?.type === "logic" &&
        selectedDiff?.index === node.index
      }
      onClick={() => {
        if (!hasChildren) {
          setSelectedDiff({ type: "logic", index: node.index });
        }
      }}
      collapseOnClickWhenNotSelected
    >
      {node.children.map((childNode) => (
        <ExpressionSideBarItem
          node={childNode}
          selectedDiff={selectedDiff}
          setSelectedDiff={setSelectedDiff}
        />
      ))}
    </SidebarItem>
  );
}

DiffEditor.LoadingSkeleton = function ({
  isCurrent,
  meta,
  customTitle,
  rightSidebar,
}: {
  isCurrent?: boolean;
  meta?: CommitMetadata;
  customTitle?: React.ReactNode;
  rightSidebar?: React.ReactNode;
}): React.ReactElement {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [leftSidebarCollapsed] = useStickyState(false, leftSidebarCollapsedKey);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [rightSidebarCollapsed] = useStickyState(
    false,
    rightSidebarCollapsedKey
  );

  return (
    <div className={`flex ${pageContainerClassName}`}>
      <TopBar
        isCurrent={isCurrent}
        meta={meta}
        timeFormat="absolute"
        customTitle={customTitle}
      />

      <div className={contentContainerClassName}>
        {!leftSidebarCollapsed && <SidebarContainer.LoadingSkeleton />}
        <div className={contentClassName}>
          <DiffContainer.LoadingSkeleton />
          <DiffContainer.LoadingSkeleton />
        </div>
        {!rightSidebarCollapsed && rightSidebar}
      </div>
    </div>
  );
};

function Message({ text }: { text: string }): React.ReactElement | null {
  return <p className="text-tx-muted">{text}</p>;
}
