import React from "react";
import {
  filterAndSortObjectEntries,
  queryObjectTypeName,
  rootObjectTypeNameFromSchema,
} from "@hypertune/shared-internal";
import { Schema } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { Code, ListBullets } from "@phosphor-icons/react";
import SidebarContainer from "../SidebarContainer";
import TopBarDropdown from "../../../components/TopBarDropdown";
import Label from "../../../components/Label";
import ModeSwitcher from "../../../components/ModeSwitcher";
import SidebarItem from "../../../components/SidebarItem";
import TypeIcon from "../../../components/icons/TypeIcon";
import { lighterGreyHex } from "../../../lib/constants";
import matchesSearch from "../../../lib/generic/matchesSearch";
import {
  SchemaEditorMode,
  SelectedType,
  TypeOption,
  TypeReferencesMap,
} from "./schemaHooks";
import { TypeFilter, typeFilters } from "./SchemaEditor";
import NewTypeButton from "./typeEditor/NewTypeButton";
import Tooltip from "../../../components/tooltips/Tooltip";
import { useHypertune } from "../../../generated/hypertune.react";
import useSearchParamsState from "../../../app/useSearchParamsState";

const searchTextQueryParamKey = "schema_search";
const typeFilterParamKey = "schema_type_filter";

export default function SchemaEditorSidebar({
  readOnly,
  schema,
  mode,
  setMode,
  selectedType,
  setSelectedType,
  typeReferences,
}: {
  readOnly: boolean;
  schema: Schema;
  mode: SchemaEditorMode;
  setMode: (newMode: SchemaEditorMode) => void;
  selectedType: SelectedType | null;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  typeReferences: TypeReferencesMap;
}): React.ReactElement | null {
  const content = useHypertune().content();
  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);

  const [searchText, setSearchText] = useSearchParamsState(
    searchTextQueryParamKey,
    ""
  );
  const [typeFilter, setTypeFilter] = useSearchParamsState<TypeFilter>(
    typeFilterParamKey,
    "All"
  );
  const typeFilterOptions = typeFilters.map((typeFilterValue) => {
    return {
      value: typeFilterValue,
      label: typeFilterValue,
    };
  });

  return (
    <SidebarContainer
      searchText={searchText}
      setSearchText={setSearchText}
      actions={!readOnly && <NewTypeButton />}
      controls={
        <div className="flex flex-row items-center justify-between border-b border-bd-darker px-[11px] py-[7px]">
          <TopBarDropdown<TypeFilter>
            value={
              typeFilterOptions.find((option) => option.value === typeFilter) ||
              null
            }
            placeholder=""
            options={{
              type: "options",
              options: typeFilterOptions,
            }}
            onChange={(newOption) => {
              if (newOption) {
                setTypeFilter(newOption.value);
              }
            }}
            dropdownStyle={{
              hideSearch: true,
              caret: "down",
              buttonClassName: "py-[9.5px] px-[7px] leading-none",
              panelClassName: "pt-1 data-top:pb-1",
              buttonPrefix: (
                <Label type="title3" className="text-tx-muted">
                  Type:{" "}
                </Label>
              ),
            }}
          />
          <Tooltip
            id="schema"
            step={1}
            allSteps={content.schema().tooltips()}
            topArrowOffset={18}
          >
            <ModeSwitcher
              modes={[
                {
                  icon: <ListBullets weight="regular" color={lighterGreyHex} />,
                  value: "type",
                },
                {
                  icon: <Code weight="regular" color={lighterGreyHex} />,
                  value: "code",
                },
              ]}
              setMode={setMode}
              selectedMode={mode}
            />
          </Tooltip>
        </div>
      }
    >
      {filterAndSortObjectEntries(schema)
        .filter(([objectTypeName, objectType]) => {
          return (
            objectTypeName !== queryObjectTypeName &&
            matchesSearch(searchText, [
              objectTypeName,
              ...Object.keys(objectType.fields),
            ]) &&
            (typeFilter === "All" ||
              (objectType.role === "input" && typeFilter === "Inputs") ||
              (objectType.role === "event" && typeFilter === "Events") ||
              (objectType.role === "output" && typeFilter === "Objects"))
          );
        })
        .map(([objectTypeName, objectType]) => {
          const typeOption =
            objectType.role === "output"
              ? "object"
              : (objectType.role as TypeOption);
          const isSelected =
            typeOption === selectedType?.type &&
            selectedType?.name === objectTypeName;

          const item = (
            <SidebarItem
              icon={<TypeIcon type={typeOption} size="small" />}
              title={toStartCase(objectTypeName)}
              isSelected={isSelected}
              onClick={() => {
                setSelectedType({
                  type: typeOption,
                  name: objectTypeName,
                  selectedChildName: null,
                  count:
                    selectedType?.count && isSelected
                      ? selectedType.count + 1
                      : 1,
                });
              }}
              className="px-3 py-[9px]"
              hasWarning={
                Object.keys(objectType.fields).length === 0 ||
                (objectType.role !== "event" &&
                  (!typeReferences[objectTypeName] ||
                    typeReferences[objectTypeName].size === 0))
              }
            />
          );
          if (objectTypeName === rootObjectTypeName) {
            return (
              <Tooltip
                id="schema"
                step={2}
                allSteps={content.schema().tooltips()}
                topArrowOffset={21}
                onNext={() =>
                  setSelectedType({
                    type: "object",
                    name: rootObjectTypeName,
                    selectedChildName: null,
                  })
                }
              >
                {item}
              </Tooltip>
            );
          }
          return item;
        })}
      {Object.entries(schema.enums)
        .filter(([enumTypeName, enumSchema]) => {
          return (
            matchesSearch(searchText, [
              enumTypeName,
              ...Object.keys(enumSchema.values),
            ]) &&
            (typeFilter === "All" || typeFilter === "Enums")
          );
        })
        .sort(([enumTypeNameA], [enumTypeNameB]) =>
          enumTypeNameA.localeCompare(enumTypeNameB)
        )
        .map(([enumTypeName, enumSchema]) => {
          const isSelected =
            selectedType?.type === "enum" && selectedType.name === enumTypeName;
          return (
            <SidebarItem
              icon={<TypeIcon type="enum" size="small" />}
              title={toStartCase(enumTypeName)}
              isSelected={isSelected}
              className="px-3 py-[9px]"
              onClick={() => {
                setSelectedType({
                  type: "enum",
                  name: enumTypeName,
                  selectedChildName: null,
                  count:
                    selectedType?.count && isSelected
                      ? selectedType.count + 1
                      : 1,
                });
              }}
              hasError={Object.keys(enumSchema.values).length === 0}
              hasWarning={
                !typeReferences[enumTypeName] ||
                typeReferences[enumTypeName].size === 0
              }
            />
          );
        })}
    </SidebarContainer>
  );
}
