import { Intent } from "../../../../components/intent";
import { useHypertune } from "../../../../generated/hypertune.react";
import { normal, small } from "../../../../lib/constants";
import { ExpressionControlContext } from "../../../../lib/types";
import LabeledExpressionControl from "./LabeledExpressionControl";

export type LabeledExpressionControlListItem =
  | {
      type: typeof LabeledExpressionControlListItemControlType;
      label: Label;
      count?: number;
      intent?: Intent;
      options: React.ReactNode[];
      expressionControl: React.ReactNode;
    }
  | {
      type: typeof LabeledExpressionControlListItemHeaderType;
      label: React.ReactNode;
    };

export const LabeledExpressionControlListItemControlType = "Control" as const;
export const LabeledExpressionControlListItemHeaderType = "Header" as const;

export type Label = {
  component: React.ReactNode;
  width: number;
};

export default function LabeledExpressionControlList({
  useArrow,
  items,
  context,
  expressionIds,
}: {
  useArrow: boolean;
  items: LabeledExpressionControlListItem[];
  context: ExpressionControlContext;
  expressionIds: string[];
}): React.ReactElement {
  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });

  const labelArrowsMinWidth =
    52 +
    Math.max(
      ...items.map((item) =>
        item.type === LabeledExpressionControlListItemControlType
          ? item.label.width
          : 0
      )
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: useArrow ? small : normal * 2,
      }}
    >
      {items.map((item, index) =>
        item.type === LabeledExpressionControlListItemControlType ? (
          <LabeledExpressionControl
            key={`labeled-expression-list-item-${expressionIds ? expressionIds[index] : index.toString()}`}
            label={item.label.component}
            count={item.count}
            useArrow={useArrow}
            arrowMinWidth={labelArrowsMinWidth}
            intent={item.intent}
            options={item.options}
            expressionControl={item.expressionControl}
            isSelected={
              isExpressionSelectabilityEnabled &&
              expressionIds &&
              expressionIds[index] !== "" &&
              expressionIds[index] ===
                context.expressionEditorState.selectedItem?.id
            }
            expresssionId={expressionIds ? expressionIds[index] : ""}
            context={context}
          />
        ) : (
          <div>
            <div style={{ marginTop: small }} />
            <LabeledExpressionControl
              key={`labeled-expression-list-item-${expressionIds ? expressionIds[index] : index.toString()}`}
              label={item.label}
              count={undefined}
              useArrow={false}
              arrowMinWidth={labelArrowsMinWidth}
              intent="neutral"
              options={[]}
              expressionControl={null}
              isSelected={
                isExpressionSelectabilityEnabled &&
                expressionIds &&
                expressionIds[index] !== "" &&
                expressionIds[index] ===
                  context.expressionEditorState.selectedItem?.id
              }
              expresssionId={expressionIds ? expressionIds[index] : ""}
              context={context}
            />
            <div style={{ marginBottom: -normal }} />
          </div>
        )
      )}
    </div>
  );
}
