import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback } from "react";
import { SortableItem } from "./SortableObjectList";

export default function SortableList<Item extends { id: string }>({
  list,
  setList,
  renderItemComponent,
  disabled,
}: {
  list: Item[];
  setList: (newList: Item[]) => void;
  renderItemComponent: React.FC<{
    id: string;
    index: number;
    dragHandle: React.ReactNode;
  }>;
  disabled?: boolean;
}): React.ReactElement | null {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = list.findIndex(({ id }) => id === active.id);
        const newIndex = list.findIndex(({ id }) => id === over.id);

        setList(arrayMove(list, oldIndex, newIndex));
      }
    },
    [setList, list]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={list}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {list.map(({ id }, index) => (
          <SortableItem
            key={id}
            id={id}
            index={index}
            renderChildren={renderItemComponent}
            disabled={disabled}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
