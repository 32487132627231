import React from "react";
import { DotsThree } from "@phosphor-icons/react";
import { blackTextHex, optionsButtonHeight } from "../../../../lib/constants";
import Menu, { MenuItem } from "../../../../components/Menu";

export type OptionsButtonItem = {
  iconImgSrc?: string;
  icon?: React.ReactNode;
  label: string;
  onClick: React.MouseEventHandler;
};

export default function OptionsButton({
  items,
}: {
  items: OptionsButtonItem[];
}): React.ReactElement {
  return (
    <div className="h-[22px]">
      <Menu
        items={items.map<MenuItem>(({ label, iconImgSrc, icon, onClick }) => ({
          title: label,
          icon: iconImgSrc ? (
            <img src={iconImgSrc} className="h-3" alt="" />
          ) : (
            icon
          ),
          onClick,
          intent: "neutral",
        }))}
        button={
          <DotsThree
            size={optionsButtonHeight}
            weight="bold"
            color={blackTextHex}
          />
        }
        itemsClassName="w-[180px]"
        openClassName="filter-intent-primary"
        className="hover:filter-intent-primary h-[22px] w-[22px] bg-transparent hover:border-0"
      />
    </div>
  );
}
