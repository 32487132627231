import React from "react";
import { Schema, Split, SplitMap } from "@hypertune/sdk/src/shared";
import getSplitErrorMessage from "@hypertune/shared-internal/src/expression/getSplitErrorMessage";
import { ArrowsSplit } from "@phosphor-icons/react";
import SidebarContainer from "../SidebarContainer";
import TopBarDropdown from "../../../components/TopBarDropdown";
import Label from "../../../components/Label";
import SidebarItem from "../../../components/SidebarItem";
import TypeIcon from "../../../components/icons/TypeIcon";
import matchesSearch from "../../../lib/generic/matchesSearch";
import NewSplitButton from "./splitEditor/NewSplitButton";
import EmptyStateContainer from "../../../components/EmptyStateContainer";
import { useAppDispatch } from "../../../app/hooks";
import { setNewSplitModalState } from "../projectSlice";
import { useHypertune } from "../../../generated/hypertune.react";
import useSearchParamsState from "../../../app/useSearchParamsState";

export const splitTypeFilters = ["All", "Tests", "ML loops"] as const;
export type SplitTypeFilter = (typeof splitTypeFilters)[number];

export const statusFilters = ["Active", "Completed", "All"] as const;
export type StatusFilter = (typeof statusFilters)[number];

const searchTextQueryParamKey = "splits_search";
const typeFilterParamKey = "splits_type_filter";
const statusFilterParamKey = "splits_status_filter";

export default function SplitsEditorSidebar({
  readOnly,
  schema,
  splits,
  selectedSplit,
  setSelectedSplitId,
}: {
  readOnly: boolean;
  schema: Schema;
  splits: SplitMap;
  selectedSplit: Split | null;
  setSelectedSplitId: (newSelectedSplitId: string) => void;
}): React.ReactElement | null {
  const content = useHypertune().content().splits();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useSearchParamsState<string>(
    searchTextQueryParamKey,
    ""
  );
  const [typeFilter, setTypeFilter] = useSearchParamsState<SplitTypeFilter>(
    typeFilterParamKey,
    "All"
  );
  const [statusFilter, setStatusFilter] = useSearchParamsState<StatusFilter>(
    statusFilterParamKey,
    "Active"
  );
  const splitTypeFilterOptions = splitTypeFilters.map((filter) => {
    return { value: filter, label: filter };
  });
  const statusFilterOptions = statusFilters.map((filter) => {
    return { value: filter, label: filter };
  });

  const hasNoSplits = Object.keys(splits).length === 0;

  return (
    <SidebarContainer
      searchText={searchText}
      setSearchText={setSearchText}
      actions={!readOnly && <NewSplitButton />}
      controls={
        <div className="flex flex-row items-center justify-between border-b border-bd-darker px-[11px] py-[7px]">
          <TopBarDropdown<SplitTypeFilter>
            value={
              splitTypeFilterOptions.find(
                (option) => option.value === typeFilter
              ) || null
            }
            placeholder=""
            options={{
              type: "options",
              options: splitTypeFilterOptions,
            }}
            onChange={(newOption) => {
              if (newOption) {
                setTypeFilter(newOption.value);
              }
            }}
            dropdownStyle={{
              hideSearch: true,
              caret: "down",
              buttonClassName: "py-[9.5px] px-[7px] leading-none",
              panelClassName: "pt-1 data-top:pb-1",
              buttonPrefix: (
                <Label type="title3" className="text-tx-muted">
                  Type:{" "}
                </Label>
              ),
            }}
          />
          <TopBarDropdown<StatusFilter>
            value={
              statusFilterOptions.find(
                (option) => option.value === statusFilter
              ) || null
            }
            placeholder=""
            options={{
              type: "options",
              options: statusFilterOptions,
            }}
            onChange={(newOption) => {
              if (newOption) {
                setStatusFilter(newOption.value);
              }
            }}
            dropdownStyle={{
              hideSearch: true,
              caret: "down",
              buttonClassName: "py-[9.5px] px-[7px] leading-none",
              panelClassName: "pt-1 data-top:pb-1",
              buttonPrefix: (
                <Label type="title3" className="text-tx-muted">
                  Status:{" "}
                </Label>
              ),
            }}
          />
        </div>
      }
      childrenClassName={hasNoSplits ? "pb-[5px]" : ""}
    >
      {hasNoSplits && (
        <EmptyStateContainer
          icon={<ArrowsSplit weight="regular" />}
          content={content.emptyState().get()}
          buttonOnClick={
            !readOnly ? () => dispatch(setNewSplitModalState({})) : undefined
          }
        />
      )}
      {Object.values(splits)
        .sort((splitA, splitB) =>
          splitA.type !== splitB.type
            ? splitA.type === "test"
              ? -1
              : 1
            : splitA.name.localeCompare(splitB.name)
        )
        .filter((split) => {
          return (
            matchesSearch(searchText, [split.name]) &&
            (typeFilter === "All" ||
              (split.type === "test" && typeFilter === "Tests") ||
              (split.type === "ml" && typeFilter === "ML loops")) &&
            (statusFilter === "All" ||
              (!split.archived && statusFilter === "Active") ||
              (split.archived && statusFilter === "Completed"))
          );
        })
        .map((split) => (
          <SidebarItem
            icon={<TypeIcon type={split.type} size="small" />}
            title={split.name}
            isSelected={selectedSplit?.id === split.id}
            onClick={() => setSelectedSplitId(split.id)}
            className="px-3 py-[9px]"
            hasError={!!getSplitErrorMessage(schema, split)}
          />
        ))}
    </SidebarContainer>
  );
}
