/* eslint-disable @typescript-eslint/ban-ts-comment */

"use client";

import { cookieConsentCookieName } from "@hypertune/shared-internal/src/constants";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import {
  blueHex,
  cookiePolicyUrl,
  dotSymbol,
  privacyPolicyUrl,
} from "../lib/constants";
import { useMeQuery } from "../generated/graphql";
import { useHypertune } from "../generated/hypertune.react";

const buttonStyle: React.CSSProperties = {
  fontSize: "13px",
  background: "white",
  color: "black",
  borderRadius: "10px",
  fontWeight: 600,
};

export default function CookieConsentBanner(): React.ReactElement | null {
  const { refetch } = useMeQuery();
  const hypertune = useHypertune().website();

  return (
    <CookieConsent
      location="bottom"
      buttonText={hypertune.cookieAcceptButtonText({ fallback: "Accept all" })}
      cookieName={cookieConsentCookieName}
      extraCookieOptions={
        window.location.hostname.includes("hypertune.com")
          ? { domain: "hypertune.com" }
          : undefined
      }
      style={{ background: blueHex, alignItems: "center" }}
      buttonStyle={buttonStyle}
      declineButtonText={hypertune.cookieDeclineButtonText({
        fallback: "Accept essential only",
      })}
      declineButtonStyle={{ ...buttonStyle, marginRight: 0 }}
      enableDeclineButton
      expires={365}
      onAccept={async () => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        function gtag(): void {
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        await refetch();
      }}
    >
      {hypertune.cookieBannerText({
        fallback:
          "This website uses essential and analytics cookies to enhance the user experience.",
      })}{" "}
      <Link
        className="font-[500] underline"
        to={cookiePolicyUrl}
        target="_blank"
      >
        Cookie policy
      </Link>
      {` ${dotSymbol} `}
      <Link
        className="font-[500] underline"
        to={privacyPolicyUrl}
        target="_blank"
      >
        Privacy policy
      </Link>
    </CookieConsent>
  );
}
