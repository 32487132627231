import {
  ObjectRole,
  ObjectSchema,
  Schema,
  ValueType,
} from "@hypertune/sdk/src/shared";
import valueTypesAreEqual from "@hypertune/shared-internal/src/schema/valueTypesAreEqual";
import {
  queryObjectTypeName,
  rootObjectTypeNameFromSchema,
} from "@hypertune/shared-internal";
import { useCallback } from "react";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Label from "../../../../../components/Label";
import TopBarDropdown, {
  DropdownStyle,
  LabeledOption,
  LabeledOptionGroup,
} from "../../../../../components/TopBarDropdown";
import TypeIcon from "../../../../../components/icons/TypeIcon";
import { TypeOption } from "../../schemaHooks";
import { useAppDispatch } from "../../../../../app/hooks";
import { useProjectSelectedState } from "../../../projectHooks";
import { setNewTypeModalState } from "../../../projectSlice";
import twMerge from "../../../../../lib/twMerge";
import canCloneObjectType from "../../../../../lib/canCloneObjectType";

const newEnumOptionTypeName = "---new-enum";
const newInputOptionTypeName = "---new-input";
const newObjectOptionTypeName = "---new-object";

export default function ValueTypeSelector({
  readOnly,
  objectTypeName,
  optionGroups,
  valueTypes,
  setValueTypes,
  dropdownStyle,
  hideFirstTitle,
  titleClassName,
  trackEditing,
  newTypeName,
  onOpenNewTypeModal,
}: {
  readOnly?: boolean;
  objectTypeName?: string;
  optionGroups: LabeledOptionGroup<ValueType>[];
  valueTypes: ValueType[];
  setValueTypes: (newValueTypes: ValueType[]) => void;
  dropdownStyle: DropdownStyle;
  hideFirstTitle?: boolean;
  titleClassName?: string;
  trackEditing?: React.Dispatch<React.SetStateAction<Set<string>>>;
  newTypeName?: string;
  onOpenNewTypeModal?: () => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const { setSelected } = useProjectSelectedState();

  const openNewTypeModal = useCallback(
    (typeOption: TypeOption) => {
      setSelected({ view: "schema" });
      dispatch(
        setNewTypeModalState({
          addFieldToObject: objectTypeName
            ? {
                objectTypeName,
                valueTypes: valueTypes.slice(0, -1),
              }
            : undefined,
          defaultTypeName: newTypeName,
          defaultTypeOption: typeOption,
        })
      );
      if (onOpenNewTypeModal) {
        onOpenNewTypeModal();
      }
    },
    [
      setSelected,
      dispatch,
      objectTypeName,
      valueTypes,
      newTypeName,
      onOpenNewTypeModal,
    ]
  );

  const allOptions = optionGroups.flatMap((group) => group.options);

  return (
    <>
      {valueTypes.map((valueType, index) => {
        return (
          <>
            {(!hideFirstTitle || index !== 0) && (
              <Label
                type="title4"
                className={twMerge(
                  "mb-[9px] mt-6 text-tx-muted",
                  titleClassName || ""
                )}
              >
                {index === 0 ? "Type" : "Item type"}
              </Label>
            )}
            <TopBarDropdown
              readOnly={readOnly}
              editTracking={
                trackEditing
                  ? {
                      id: `value-type-select-${index}`,
                      track: trackEditing,
                    }
                  : undefined
              }
              value={
                valueType
                  ? allOptions.find((option) =>
                      valueTypesAreEqual(option.value, valueType)
                    ) || null
                  : null
              }
              placeholder="Select type..."
              options={{
                type: "groups",
                groups: optionGroups,
              }}
              onChange={(newOption) => {
                if (newOption === null) {
                  return;
                }
                if (
                  newOption.value.type === "EnumValueType" &&
                  newOption.value.enumTypeName === newEnumOptionTypeName
                ) {
                  openNewTypeModal("enum");
                  return;
                }
                if (
                  newOption.value.type === "ObjectValueType" &&
                  (newOption.value.objectTypeName === newInputOptionTypeName ||
                    newOption.value.objectTypeName === newObjectOptionTypeName)
                ) {
                  openNewTypeModal(
                    newOption.value.objectTypeName === newInputOptionTypeName
                      ? "input"
                      : "object"
                  );
                  return;
                }
                const newValueTypes =
                  newOption.value.type === "ListValueType"
                    ? [...valueTypes.slice(0, index + 1), allOptions[0].value]
                    : valueTypes.slice(0, index + 1);

                newValueTypes[index] = newOption.value;
                setValueTypes(newValueTypes);
              }}
              dropdownStyle={dropdownStyle}
            />
          </>
        );
      })}
    </>
  );
}

export function valueTypeOptionGroupsFromSchema(
  schema: Schema,
  parentObjectTypeName: string,
  includeNewOptions = true
): LabeledOptionGroup<ValueType>[] {
  if (!schema.objects[parentObjectTypeName]) {
    return [];
  }
  const rootObjectTypeName = rootObjectTypeNameFromSchema(schema);
  const parentObjectRole = schema.objects[parentObjectTypeName].role;
  const useRoles: ObjectRole[] =
    parentObjectRole === "input"
      ? ["input"]
      : parentObjectRole === "event"
        ? ["input", "event"]
        : ["output"];

  const objectRoleName = getObjectRoleName(parentObjectRole);

  const filteredObjectEntries = Object.entries(schema.objects).filter(
    ([objectTypeName, objectSchema]) => {
      const includeObjectType = useRoles.includes(objectSchema.role);

      return (
        includeObjectType &&
        objectSchema.role !== "args" &&
        objectTypeName !== queryObjectTypeName &&
        objectTypeName !== rootObjectTypeName &&
        objectTypeName !== parentObjectTypeName
      );
    }
  );
  const additionalObjectsRole =
    parentObjectRole === "input"
      ? "output"
      : parentObjectRole === "output"
        ? "input"
        : null;

  const additionalObjectEntries = additionalObjectsRole
    ? Object.entries(schema.objects).filter(
        ([objectTypeName, objectSchema]) => {
          return (
            objectSchema.role === additionalObjectsRole &&
            objectTypeName !== queryObjectTypeName &&
            objectTypeName !== rootObjectTypeName &&
            objectTypeName !== parentObjectTypeName &&
            canCloneObjectType(schema, objectTypeName)
          );
        }
      )
    : [];

  return [
    primitiveValueTypeOptionGroup(
      /* includeVoid */ useRoles.includes("output")
    ),
    enumValueTypeOptionGroupFromSchema(schema, includeNewOptions),
    getObjectValueTypeOptionGroup(
      filteredObjectEntries,
      useRoles,
      objectRoleName,
      /* includeNewOptions */ true
    ),
    ...(additionalObjectsRole && additionalObjectEntries.length > 0
      ? [
          getObjectValueTypeOptionGroup(
            additionalObjectEntries,
            [additionalObjectsRole],
            objectRoleName,
            /* includeNewOptions */ true
          ),
        ]
      : []),
  ];
}

export function getObjectRoleName(objectRole: ObjectRole): TypeOption {
  return objectRole === "output"
    ? "object"
    : objectRole === "event"
      ? "input"
      : (objectRole as TypeOption);
}

export function primitiveValueTypeOptionGroup(
  includeVoid: boolean
): LabeledOptionGroup<ValueType> {
  return {
    label: "Primitive types",
    options: [
      {
        label: "Boolean",
        value: { type: "BooleanValueType" },
        icon: <TypeIcon type="boolean" size="large" />,
        showIconWhenSelected: true,
        subtitle: "True or false",
      },
      {
        label: "Integer",
        value: { type: "IntValueType" },
        icon: <TypeIcon type="int" size="large" />,
        showIconWhenSelected: true,
        subtitle: "Whole numbers",
      },
      {
        label: "Float",
        value: { type: "FloatValueType" },
        icon: <TypeIcon type="float" size="large" />,
        showIconWhenSelected: true,
        subtitle: "Decimal numbers",
      },
      {
        label: "String",
        value: { type: "StringValueType" },
        icon: <TypeIcon type="string" size="large" />,
        showIconWhenSelected: true,
        subtitle: "Unformatted text",
      },
      {
        label: "List",
        value: placeholderListValueType(),
        icon: <TypeIcon type="list" size="large" />,
        showIconWhenSelected: true,
        subtitle: "A list of values",
      },
      ...(includeVoid
        ? ([
            {
              label: "Event trigger",
              value: { type: "VoidValueType" },
              icon: <TypeIcon type="event" size="large" />,
              showIconWhenSelected: true,
              subtitle: "Analytics event trigger",
            },
          ] as LabeledOption<ValueType>[])
        : []),
    ],
  };
}

export function enumValueTypeOptionGroupFromSchema(
  schema: Schema,
  includeNewOptions: boolean
): LabeledOptionGroup<ValueType> {
  return {
    label: "Enums",
    options: Object.entries(schema.enums)
      .map<LabeledOption<ValueType>>(([enumTypeName, enumType]) => {
        return {
          label: toStartCase(enumTypeName),
          value: { type: "EnumValueType", enumTypeName },
          icon: <TypeIcon type="enum" size="large" />,
          showIconWhenSelected: true,
          subtitle: enumType.description || undefined,
          disabled: Object.keys(enumType.values).length === 0,
          disabledMessage: "Unavailable until values are added to this enum",
        };
      })
      .concat(
        includeNewOptions
          ? [
              {
                label: "New enum",
                value: {
                  type: "EnumValueType",
                  enumTypeName: newEnumOptionTypeName,
                },
                icon: <TypeIcon type="enum" size="large" isAddNew />,
                subtitle: "Create a custom enum type",
              },
            ]
          : []
      ),
  };
}

export function getObjectValueTypeOptionGroup(
  filteredObjectEntries: [string, ObjectSchema][],
  useRoles: ObjectRole[],
  newObjectRoleName: TypeOption,
  includeNewOptions: boolean
): LabeledOptionGroup<ValueType> {
  return {
    label: useRoles.includes("input") ? "Input objects" : "Objects",
    options: filteredObjectEntries
      .map<LabeledOption<ValueType>>(([objectTypeName, objectType]) => {
        return {
          label: toStartCase(objectTypeName),
          value: { type: "ObjectValueType", objectTypeName },
          icon: (
            <TypeIcon
              type={
                objectType.role === "output"
                  ? "object"
                  : (objectType.role as TypeOption)
              }
              size="large"
            />
          ),
          showIconWhenSelected: true,
          subtitle: objectType.description || undefined,
        };
      })
      .concat(
        includeNewOptions
          ? [
              {
                label: `New ${newObjectRoleName}`,
                value: {
                  type: "ObjectValueType",
                  objectTypeName:
                    newObjectRoleName === "input"
                      ? newInputOptionTypeName
                      : newObjectOptionTypeName,
                },
                icon: (
                  <TypeIcon type={newObjectRoleName} size="large" isAddNew />
                ),
                subtitle: `Create a custom ${newObjectRoleName} type`,
              },
            ]
          : []
      ),
  };
}

export function combineValueTypes(valueTypes: (ValueType | null)[]): ValueType {
  if (valueTypes.length === 0 || valueTypes[0] === null) {
    throw new Error(`Invalid value types: ${JSON.stringify(valueTypes)}`);
  }
  if (valueTypes.length === 1) {
    return valueTypes[0];
  }
  return {
    type: "ListValueType",
    // This is just a placeholder as the value must be populated.
    itemValueType: combineValueTypes(valueTypes.slice(1)),
  };
}

export function unrollValueType(valueType: ValueType): ValueType[] {
  switch (valueType.type) {
    case "FunctionValueType":
      return unrollValueType(valueType.returnValueType);

    case "ListValueType":
      return [
        placeholderListValueType(),
        ...unrollValueType(valueType.itemValueType),
      ];
    default:
      return [valueType];
  }
}

function placeholderListValueType(): ValueType {
  return {
    type: "ListValueType",
    // This is just a placeholder as the value must be populated.
    itemValueType: { type: "VoidValueType" },
  };
}
