import { BooleanExpression, Expression } from "@hypertune/sdk/src/shared";

import { ExpressionControlContext } from "../../../../lib/types";
import Toggle from "../../../../components/Toggle";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function BooleanExpressionControl({
  expression,
  context,
  setExpression,
  optionsButton,
  dragHandle,
}: {
  expression: BooleanExpression;
  context: ExpressionControlContext;
  setExpression: (newExpression: Expression | null) => void;
  optionsButton: React.ReactNode;
  dragHandle?: React.ReactNode;
}): React.ReactElement {
  const hypertuneRoot = useHypertune();
  return (
    <div
      className="-ml-1 flex h-3 flex-row items-center justify-between gap-2"
      onMouseDown={(event) => {
        if (hypertuneRoot.expressionsSelectability({ fallback: false })) {
          context.setExpressionEditorState({
            ...context.expressionEditorState,
            selectedItem: { type: "expression", id: expression.id },
          });
          event.stopPropagation();
        }
      }}
    >
      <div className="flex flex-row items-center gap-2">
        {dragHandle}
        <Toggle
          key={expression.id}
          disabled={isReadOnly(context)}
          className="mx-[1.5px]"
          value={expression.value}
          setValue={(newValue) => {
            setExpression({
              ...expression,
              value: newValue,
            });
            if (hypertuneRoot.expressionsSelectability({ fallback: false })) {
              context.setExpressionEditorState({
                ...context.expressionEditorState,
                selectedItem: { type: "expression", id: expression.id },
              });
            }
          }}
        />
      </div>
      {optionsButton && <div>{optionsButton}</div>}
    </div>
  );
}
