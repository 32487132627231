import { diff } from "deep-object-diff";
import {
  fieldPathSeparator,
  mapExpressionWithResult,
} from "@hypertune/sdk/src/shared";
import {
  ExpressionMap,
  ExpressionMapPointerMap,
  ExpressionMapValue,
  getExpressionMapPointersMap,
} from "@hypertune/shared-internal/src/expressionMap";
import { rootFieldName } from "@hypertune/shared-internal";
import { Intent } from "../../../components/intent";
import { ExpressionNodeMap } from "../logic/expression/toTree";
import { StrictDeepPartial } from "../../../lib/types";

export default function getDiffExpressionPathsAndIntentMap({
  currentCommitTree,
  newCommitTree,
  currentCommitExpressionMap,
  newCommitExpressionMap,
}: {
  currentCommitTree: ExpressionNodeMap;
  newCommitTree: ExpressionNodeMap;
  currentCommitExpressionMap: ExpressionMap;
  newCommitExpressionMap: ExpressionMap;
}): {
  expressionDiffPaths: string[][];
  currentExpressionIntentMap: Record<string, Intent>;
  newExpressionIntentMap: Record<string, Intent>;
} {
  const currentPointersMap = getExpressionMapPointersMap(
    currentCommitExpressionMap
  );
  const newPointersMap = getExpressionMapPointersMap(newCommitExpressionMap);

  const expressionMapDiff = diff(
    currentCommitExpressionMap,
    newCommitExpressionMap
  );
  const pointerMapDiff = diff(currentPointersMap, newPointersMap);

  console.debug("Expression diff value", {
    expressionMapDiff,
    pointerMapDiff,
  });

  const newExpressionIntentMap = diffToNewExpressionIntentMap(
    expressionMapDiff,
    currentCommitExpressionMap,
    newCommitExpressionMap
  );
  const expressionDiffPaths = getDiffExpressionPaths({
    currentCommitTree,
    newCommitTree,
    newExpressionIntentMap,
    pointerMapDiff,
  });

  const currentExpressionIntentMap: Record<string, Intent> = Object.fromEntries(
    Object.keys(newExpressionIntentMap).map((id) => [id, "danger"])
  );
  return {
    expressionDiffPaths,
    currentExpressionIntentMap,
    newExpressionIntentMap,
  };
}

function getDiffExpressionPaths({
  currentCommitTree,
  newCommitTree,
  newExpressionIntentMap,
  pointerMapDiff,
}: {
  currentCommitTree: ExpressionNodeMap;
  newCommitTree: ExpressionNodeMap;
  newExpressionIntentMap: { [expressionId: string]: Intent };
  pointerMapDiff: StrictDeepPartial<ExpressionMapPointerMap>;
}): string[][] {
  if (
    Object.keys(currentCommitTree[rootFieldName]?.childExpressions ?? {})
      .length === 0 ||
    Object.keys(newCommitTree[rootFieldName].childExpressions ?? {}).length ===
      0
  ) {
    // When there are no child expressions for one of the root nodes then
    // we show the diff for the whole tree.
    return [[rootFieldName]];
  }

  const result: { [stringPath: string]: string[] } = {};

  collectTreeDiffPaths({
    diffPathMap: result,
    tree: currentCommitTree,
    currentPath: [],
    newExpressionIntentMap,
    pointerMapDiff,
  });
  collectTreeDiffPaths({
    diffPathMap: result,
    tree: newCommitTree,
    currentPath: [],
    newExpressionIntentMap,
    pointerMapDiff,
  });

  if (Object.keys(result).length > 1) {
    // Remove root as it will always be present when there are changes.
    delete result[rootFieldName];
    const resultKeys = Object.keys(result);

    // Remove paths which has a parent path in the diff list.
    // This ensures we show the correct diff when an expression changes and
    // this results on more child paths e.g. when adding exhaustive enum switch.
    resultKeys.forEach((filterPath) => {
      resultKeys.forEach((path) => {
        if (
          path in result &&
          filterPath !== path &&
          path.startsWith(filterPath + fieldPathSeparator)
        ) {
          delete result[path];
        }
      });
    });
  }

  return Object.values<string[]>(result).sort((pathA, pathB) =>
    pathA.join(fieldPathSeparator).localeCompare(pathB.join(fieldPathSeparator))
  );
}

function collectTreeDiffPaths({
  diffPathMap,
  tree,
  currentPath,
  newExpressionIntentMap,
  pointerMapDiff,
}: {
  diffPathMap: { [stringPath: string]: string[] };
  tree: ExpressionNodeMap;
  currentPath: string[];
  // We use the intent map as that includes list expression item
  // ids that were reordered.
  newExpressionIntentMap: { [expressionId: string]: Intent };
  pointerMapDiff: StrictDeepPartial<ExpressionMapPointerMap>;
}): boolean {
  let result = false;

  Object.entries(tree).forEach(([step, node]) => {
    const nodePath = [...currentPath, step];

    if (node.childExpressions) {
      const childrenHaveChanges = collectTreeDiffPaths({
        diffPathMap,
        tree: node.childExpressions,
        currentPath: nodePath,
        newExpressionIntentMap,
        pointerMapDiff,
      });
      if (childrenHaveChanges) {
        result = true;
        return;
      }
    }
    const hasChanged = mapExpressionWithResult<boolean>(
      (expr) => {
        // We check whether the key is present as deleting
        // will result in the value being undefined
        return {
          newExpression: expr,
          mapResult:
            !!expr &&
            (expr.id in newExpressionIntentMap ||
              specialFieldsArray.some(
                (fieldName) => expr.id + fieldName in newExpressionIntentMap
              )),
        };
      },
      (...results) => results.some(Boolean),
      node.expression
    ).mapResult;

    if (
      hasChanged ||
      (node.expression && node.expression.id in pointerMapDiff)
    ) {
      diffPathMap[nodePath.join(fieldPathSeparator)] = nodePath;
      result = true;
    }
  });
  return result;
}

function diffToNewExpressionIntentMap(
  diffValue: StrictDeepPartial<ExpressionMap>,
  currentCommitExpressionMap: ExpressionMap,
  newCommitExpressionMap: ExpressionMap
): {
  [expressionId: string]: Intent;
} {
  return Object.fromEntries(
    Object.entries(diffValue).flatMap(([expressionId, valueDiff]) => {
      if (valueDiff === undefined) {
        return getExpressionMapValueIntents(
          currentCommitExpressionMap[expressionId],
          currentCommitExpressionMap[expressionId]
        );
      }

      return getExpressionMapValueIntents(
        newCommitExpressionMap[expressionId],
        valueDiff
      );
    })
  );
}

const specialFields = new Set([
  "splitId",
  "dimensionId",
  "eventTypeId",
  "operator",
  "eventObjectTypeName",
  "fieldPath",
]);

const specialFieldsArray = [...specialFields];

const baseExpressionTypes = new Set([
  "NoOpExpression",
  "BooleanExpression",
  "StringExpression",
  "IntExpression",
  "FloatExpression",
  "RegexExpression",
  "EnumExpression",
  "VariableExpression",
]);

function getExpressionMapValueIntents(
  expression: ExpressionMapValue,
  valueDiff: StrictDeepPartial<ExpressionMapValue>
): [string, Intent][] {
  const result: [string, Intent][] =
    "type" in valueDiff ||
    "valueType" in valueDiff ||
    "metadata" in valueDiff ||
    baseExpressionTypes.has(expression.type)
      ? [[expression.id, "success"]]
      : [];

  Object.keys(valueDiff).forEach((fieldName) => {
    if (specialFields.has(fieldName)) {
      result.push([expression.id + fieldName, "success"]);
    }

    if ("metadata" in valueDiff && valueDiff.metadata?.tags) {
      Object.keys(valueDiff.metadata.tags).map((tagName) =>
        result.push([`${expression.id}_metadata_${tagName}`, "success"])
      );
    }
  });

  switch (expression.type) {
    case "SwitchExpressionMapValue":
      if ("casesWeights" in valueDiff && valueDiff.casesWeights) {
        Object.keys(valueDiff.casesWeights).forEach((caseId) =>
          result.push([`${expression.id}case${caseId}`, "success"])
        );
      }
      break;

    case "ListExpressionMapValue":
      if ("itemsWeights" in valueDiff && valueDiff.itemsWeights) {
        Object.keys(valueDiff.itemsWeights).forEach((itemId) =>
          result.push([itemId, "success"])
        );
      }
      break;
    default:
  }

  return result;
}
