import {
  EnumSchema,
  Expression,
  Schema,
  asError,
} from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import {
  EnumValuePosition,
  formatEnumValueSchemaName,
  renameEnumValue,
  renameEnumValueInExpression,
} from "@hypertune/shared-internal";
import { useCallback, useState } from "react";

import MutableText from "../../../../../components/input/MutableText";
import { useAppDispatch } from "../../../../../app/hooks";
import {
  setDraftCommitSchema,
  setDraftCommitSchemaAndExpression,
} from "../../../projectSlice";
import { lighterGreyHex, whiteHex } from "../../../../../lib/constants";
import Button from "../../../../../components/buttons/Button";
import Label from "../../../../../components/Label";
import EnumAddValueModal from "./EnumAddValueModal";
import SearchInput from "../../../../../components/SearchInput";
import matchesSearch from "../../../../../lib/generic/matchesSearch";
import { SelectedType } from "../../schemaHooks";
import Card from "../../../../../components/Card";
import { enumValueNameError, showSchemaNameError } from "../SchemaNameError";
import { useHypertune } from "../../../../../generated/hypertune.react";
import SortableObjectList from "../../../../../components/SortableObjectList";

export default function EnumEditor({
  readOnly,
  schema,
  expression,
  enumTypeName,
  selectedType,
  setSelectedType,
  setErrorMessage,
  fieldsAndValuesSearchText,
  setFieldsAndValuesSearchText,
}: {
  readOnly: boolean;
  schema: Schema;
  expression: Expression;
  enumTypeName: string;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  fieldsAndValuesSearchText: string;
  setFieldsAndValuesSearchText: (newSearchText: string) => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();

  const [newValueModal, setNewValueModal] = useState<{
    isVisible: boolean;
    valuePosition?: EnumValuePosition;
  }>({ isVisible: false });

  const setEnumValues = useCallback(
    (newEnumValues: EnumSchema["values"]) => {
      dispatch(
        setDraftCommitSchema({
          ...schema,
          enums: {
            ...schema.enums,
            [enumTypeName]: {
              ...schema.enums[enumTypeName],
              values: newEnumValues,
            },
          },
        })
      );
    },
    [dispatch, enumTypeName, schema]
  );

  return (
    <>
      <div className="mb-[6px] flex flex-wrap items-center justify-between gap-2">
        <Label className="whitespace-nowrap" type="title1">
          Possible values
        </Label>
        <div className="flex flex-row gap-2">
          <SearchInput
            key={`${enumTypeName}-search`}
            searchText={fieldsAndValuesSearchText}
            setSearchText={setFieldsAndValuesSearchText}
            style={{ backgroundColor: whiteHex }}
          />
          {!readOnly && (
            <Button
              disabled={readOnly}
              intent="primary"
              weight="filled"
              size="large"
              icon={<Plus weight="regular" color="white" />}
              text="Add"
              onClick={() =>
                setNewValueModal({ isVisible: true, valuePosition: "first" })
              }
            />
          )}
        </div>
      </div>

      <SortableObjectList
        disabled={readOnly}
        object={schema.enums[enumTypeName].values}
        setObject={setEnumValues}
        includeId={(enumValueName) =>
          matchesSearch(fieldsAndValuesSearchText, [enumValueName])
        }
        renderItemComponent={({ id: enumValueName, dragHandle }) => (
          <EnumValue
            key={enumValueName}
            readOnly={readOnly}
            schema={schema}
            expression={expression}
            enumValueName={enumValueName}
            enumTypeName={enumTypeName}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            setErrorMessage={setErrorMessage}
            dragHandle={dragHandle}
          />
        )}
      />
      {!readOnly && (
        <div className="flex flex-row">
          <div className="bg-white">
            <Button
              disabled={readOnly}
              intent="neutral"
              weight="outlined"
              size="large"
              icon={<Plus weight="regular" />}
              text="Add"
              onClick={() =>
                setNewValueModal({ isVisible: true, valuePosition: "last" })
              }
            />
          </div>
        </div>
      )}
      {newValueModal.isVisible && (
        <EnumAddValueModal
          schema={schema}
          enumTypeName={enumTypeName}
          valuePosition={newValueModal.valuePosition}
          onClose={() => setNewValueModal({ isVisible: false })}
          setSelectedType={setSelectedType}
        />
      )}
    </>
  );
}

function EnumValue({
  readOnly,
  schema,
  expression,
  enumValueName,
  enumTypeName,
  selectedType,
  setSelectedType,
  setErrorMessage,
  dragHandle,
}: {
  readOnly: boolean;
  schema: Schema;
  expression: Expression;
  enumValueName: string;
  enumTypeName: string;
  selectedType: SelectedType;
  setSelectedType: (newSelectedType: SelectedType | null) => void;
  setErrorMessage: (newErrorMessage: string | null) => void;
  dragHandle: React.ReactNode;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const content = useHypertune().content();

  return (
    <Card
      layout="horizontal-with-icon"
      key={enumValueName}
      className="max-w-full gap-2 px-[10px]"
      isSelected={
        selectedType?.name === enumTypeName &&
        selectedType?.selectedChildName === enumValueName
      }
      onMouseDown={(event) => {
        event.stopPropagation();
        setSelectedType({
          ...selectedType,
          selectedChildName: enumValueName,
        });
      }}
    >
      {dragHandle}
      <div>
        <MutableText
          readOnly={readOnly}
          text={enumValueName}
          setText={async (newValueName) => {
            try {
              await dispatch(
                setDraftCommitSchemaAndExpression({
                  schema: renameEnumValue(
                    schema,
                    enumTypeName,
                    enumValueName,
                    newValueName
                  ),
                  expression: renameEnumValueInExpression(
                    expression,
                    enumTypeName,
                    enumValueName,
                    newValueName
                  ) as Expression,
                })
              );
              setSelectedType({
                ...selectedType,
                selectedChildName: formatEnumValueSchemaName(newValueName),
              });
            } catch (error) {
              setErrorMessage(asError(error).message);
            }
          }}
          showPencil={false}
          stopClickPropagation={false}
          style={{
            lineHeight: "16px",
            color: lighterGreyHex,
            maxWidth: "100%",
          }}
          minWidth={0}
          className="max-w-full overflow-x-clip text-ellipsis whitespace-nowrap"
          confirmModalContent={content.schema().renameConfirmation().get()}
          confirmModalVariables={{ entityName: "enum value" }}
          hasError={(newName) => {
            const newFormattedName = formatEnumValueSchemaName(newName);
            if (newFormattedName === enumValueName) {
              return null;
            }
            return enumValueNameError(schema, enumTypeName, newFormattedName);
          }}
          showError={showSchemaNameError}
        />
      </div>
    </Card>
  );
}
